import { useEffect, useState } from "react";
import { useFetch } from "../../../../context/hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { useParams } from "react-router-dom";
import { CATEGORY_PROVIDER_MAP } from "../../../../static/constants/constants";

export const useRunReportView = () => {
  const [run, setRun] = useState(null);
  const [pageData, setPageData] = useState({});
  const [runFeatures, setRunFeatures] = useState([]);
  const [gettingPage, setGettingPage] = useState(null);
  const [scenariosLength, setScenariosLength] = useState(null);
  const { runType } = useParams();

  const { get, status, body, error } = useFetch();

  const getRun = runId =>
    get({
      ...ENDPOINTS.RUN,
      path: ENDPOINTS.RUN.path
        .replace(":id", runId)
        .replace(":provider", CATEGORY_PROVIDER_MAP[runType]),
    });

  const getRunWithParams = (
    runId,
    { statusFilter, tags, attempts = false, feature },
    page = 1
  ) => {
    if (page === gettingPage || status?.isLoading) return;
    setGettingPage(page);
    let url = `${ENDPOINTS.RUN.path
      .replace(":id", runId)
      .replace(":provider", CATEGORY_PROVIDER_MAP[runType])}?page=${page}&`;
    if (statusFilter && statusFilter.length)
      url = url.concat(`status=${statusFilter.join(",")}&`);
    if (tags && tags.length) url = url.concat(`tags=${tags.join(",")}&`);
    if (feature) url = url.concat(`feature=${feature.replace(/ /g, "")}&`);
    if (attempts) url = url.concat(`attempts=${attempts}&`);

    if (url.at(-1) === "&") url = url.slice(0, -1);
    get({ ...ENDPOINTS.RUN, path: url });
  };

  useEffect(() => {
    if (pageData.firstPage && !pageData.lastPage)
      setPageData(e => ({ ...e, lastPage: e.firstPage }));
    if (pageData.lastPage && !pageData.firstPage)
      setPageData(e => ({ ...e, firstPage: e.lastPage }));
  }, [pageData]);

  useEffect(() => {
    const scenarios = runFeatures.reduce(
      (accumulator, feature) => accumulator + feature.scenarios.length,
      0
    );
    setScenariosLength(scenarios);
  }, [runFeatures]);

  useEffect(() => {
    if (status.isResolved && body) {
      if (body.run) {
        setRun(body.run);
        if (gettingPage < pageData.firstPage) {
          setRunFeatures(e => [...body.run.features, ...e]);
          setPageData(e => ({
            ...e,
            firstPage: body.page,
            lastPage: e.lastPage ? e.lastPage : body.page,
            totalPages: body.total_pages,
            totalScenarios: body.total_scenarios,
          }));
        } else {
          setRunFeatures(e => [...e, ...body.run.features]);
          setPageData(e => ({
            ...e,
            lastPage: body.page,
            firstPage: e.firstPage ? e.firstPage : body.page,
            totalPages: body.total_pages,
            totalScenarios: body.total_scenarios,
          }));
        }
        setGettingPage(false);
      } else setRun(body);
    }
  }, [status.isResolved, body]);

  const resetPageData = () => setPageData({ firstPage: 1, lastPage: 1 });

  const resetRunFeatures = () => setRunFeatures([]);

  return {
    run,
    error,
    getRun,
    pageData,
    runFeatures,
    resetPageData,
    scenariosLength,
    resetRunFeatures,
    getRunWithParams,
    isFetching: status.isLoading,
    isResolved: status.isResolved,
  };
};
