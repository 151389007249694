import { useTranslation } from "react-i18next";
import {
  EmptyContentContainer,
  SectionContainer,
  SectionContent,
  SectionTitle,
} from "../home.styles";
import { TestEvolveMinimalRunCard } from "../../../components/runCard/minimalRunCards/testEvolveFunctional/testEvolveMinimalCard";
import { FakeMinimalRunCard } from "../../../components/runCard/minimalRunCards/minimalRunCard.styles";
import { useHome } from "../context/home.context";

export const LatestRuns = () => {
  const { t } = useTranslation();
  const { latestRuns, loadingLatestRuns } = useHome();

  return (
    <SectionContainer>
      <SectionTitle>{t("projectHome.latestRunsTitle")}</SectionTitle>
      <p>{t("projectHome.latestRunsText")}</p>
      <SectionContent tabIndex={0}>
        {loadingLatestRuns && !latestRuns?.length ? (
          [...Array(5)].map((_, i) => (
            <FakeMinimalRunCard key={`fake-in-progress-${i}`} />
          ))
        ) : latestRuns && latestRuns?.length ? (
          latestRuns.map(run => (
            <TestEvolveMinimalRunCard
              key={`latest-run-${run.id}`}
              run={run}
              category={run.category}
            />
          ))
        ) : (
          <EmptyContentContainer></EmptyContentContainer>
        )}
      </SectionContent>
    </SectionContainer>
  );
};
