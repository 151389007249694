import styled, { css } from "styled-components";
import { SvgIcon } from "../../components/icon/icon";
import { NodeCardContainer } from "../nodeView/common/NodeCard.styles";

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme, isNotDesktop }) =>
    isNotDesktop ? "0px" : theme.spacing.paddingSM};
  width: 100%;
  align-items: flex-start;
  overflow: hidden;
  height: ${({ theme }) => (theme.isMobile ? "" : "100%")};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isNotDesktop ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme }) => theme.spacing.padding};

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const InsideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  width: 100%;
  align-items: ${({ isNotDesktop }) => isNotDesktop && "center"};
  overflow-y: scroll;
  min-height: fit-content;
  height: fit-content;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing.padding} 0;
  scroll-snap-type: x mandatory;
  height: ${({ theme }) => theme.sizing.nodeCardContainerHeight};
  min-height: ${({ theme }) => theme.sizing.nodeCardContainerHeight};

  ${({ isNotDesktop }) =>
    isNotDesktop &&
    css`
      ${NodeCardContainer} {
        &:first-of-type {
          margin-left: calc(
            (100%-${({ theme }) => theme.sizing.nodeCardWidth}) / 2
          );
        }
        &:last-of-type {
          margin-right: calc(
            (100%-${({ theme }) => theme.sizing.nodeCardWidth}) / 2
          );
        }
      }
    `};

  gap: ${({ theme }) => theme.spacing.padding};
  width: 100%;
  overflow-x: auto;
  transition: 0.3s;

  &:hover {
    ::-webkit-scrollbar {
      opacity: 0.8;
      transition: 0.3s;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colours.background};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colours.highlight};
    }
  }

  ::-webkit-scrollbar {
    background: transparent;
    width: ${({ theme }) => theme.sizing.scrollBarWidth};
    height: ${({ theme }) => theme.sizing.scrollBarWidth};
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: ${({ theme }) => theme.borders.radius};
  }
`;

export const HierarchyContainer = styled.div`
  display: flex;
  max-height: 80%;
  height: 25vh;
  width: 100%;
  overflow: auto;
`;

export const EntryContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.padding};
  width: ${({ theme }) => `calc(100% - ${theme.spacing.padding})`};
`;

export const RightChevron = styled(SvgIcon)`
  height: ${({ theme }) => theme.sizing.svgHeightSM};
  width: auto;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(90deg)")};
  transition: ${({ theme }) => theme.transitionTime.fast};
  color: ${({ theme }) => theme.colours.mainText};
  opacity: ${({ active }) => (active ? "100%" : "50%")};
  margin-right: ${({ theme }) => theme.spacing.paddingSM};
`;

export const NodeEntry = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.sizing.dropdownRowHeight};
  width: 100%;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.radius};

  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => theme.colours.mainText};
  }

  background-color: ${({ selected, theme }) =>
    selected ? theme.colours.haloAccent : theme.colours.foreground};
  color: ${({ selected, theme }) =>
    selected ? theme.colours.white : theme.colours.mainText};
`;
