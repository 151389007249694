import styled, { css } from "styled-components";
import Select from "react-select";
import { Button } from "../../../../components/Button/Button";

export const StyledContainer = styled.div`
  display: ${({ isNotDesktop }) => isNotDesktop && "flex"};
  flex-direction: column;
  min-width: 7rem;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  align-items: ${({ isNotDesktop }) => isNotDesktop && "center"};
  justify-content: ${({ isNotDesktop }) => isNotDesktop && "center"};
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const StyledMessageContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.padding};
  font-size: ${({ theme }) => theme.fontSize.smaller};
`;

export const TitleContainer = styled.div`
  display: ${({ isNotDesktop }) => isNotDesktop && "flex"};
  align-items: ${({ isNotDesktop }) => isNotDesktop && "center"};
  justify-content: ${({ isNotDesktop }) => isNotDesktop && "center"};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.padding};
  min-width: 20rem;
`;

export const StyledFormContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: ${({ isNotDesktop }) => (isNotDesktop ? "column" : "row")};

  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  .Field {
    &:nth-child(1) {
      flex-grow: 0;
      flex-basis: 25%;
    }

    &:nth-child(2) {
      flex-grow: 0;
      flex-basis: 35%;
    }

    &:nth-child(3) {
      flex-grow: 0;
      flex-basis: 20%;
    }

    &:nth-child(4) {
      flex-grow: 0;
      flex-basis: 15%;
    }

    &:nth-child(5) {
      flex-grow: 0;
      flex-basis: 35%;
    }
  }
`;

export const StyledFieldContainer = styled.div`
  position: relative;
  width: 100%;

  display: block;
  flex-grow: 1;
  flex-shrink: 0;
  align-self: auto;
  order: 0;
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const ButtonAndSpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const StyledButton = styled(Button)`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.padding};
  margin-left: auto;
  margin-right: auto;
`;

export const HiddenRadio = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const StyledRadio = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  border: ${({ theme }) => theme.colours.haloAccent} 1.5px solid;
  background: ${({ theme }) =>
    props =>
      props.checked ? theme.colours.haloAccent : "transparent"};
  border-radius: 100%;
  transition: all 150ms;

  &:after {
    content: "";
    display: ${props => (props.checked ? "block" : "none")};
    width: 7px;
    height: 14px;
    border: solid ${({ theme }) => theme.colours.white};
    border-width: 0 3px 3px 0;
    position: absolute;
    top: calc(50% - 1px);
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export const RadioContainer = styled.div`
  width: 25%;
  padding-left: ${({ theme }) => theme.spacing.padding};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  display: inline-block;
`;

export const RadioLabel = styled.label`
  display: flex;
  flex-direction: column;
`;

export const RadioLabelText = styled.div`
  display: inline-block;
  padding-bottom: 4px;
`;

export const StyledDeleteButton = styled(Button)`
  cursor: pointer;
  height: ${({ theme }) => theme.sizing.inputHeight};
  min-height: ${({ theme }) => theme.sizing.inputHeight};
`;

export const StyledAddButton = styled(Button)`
  clear: both;
  position: relative;
  cursor: pointer;
  height: 1.5rem;
  border: ${({ theme }) => theme.colours.haloAccent} 1.5px solid;
  background: ${({ theme }) => theme.colours.haloAccent};
  border-radius: ${({ theme }) => theme.borders.radius};
  transition: all 150ms;
  height: ${({ theme }) => theme.sizing.btnHeight};
`;

export const RecipientsContainer = styled.div`
  display: block;
  overflow: auto;
`;

export const RecipientContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const StyledSelect = styled(Select)`
  max-width: ${({ theme }) => theme.sizing.selectMaxWidth};
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
  margin-top: ${({ theme }) => theme.spacing.padding};
  color: ${({ theme }) => theme.colours.mainText};

  & .Select__single-value {
    color: ${({ theme }) => theme.colours.white};
  }

  & .Select__input-container {
    color: ${({ theme }) => theme.colours.mainText};
  }

  & .Select__control {
    background-color: ${({ theme }) => theme.colours.background};
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borders.radius};
    box-shadow: none;
    color: ${({ theme }) => theme.colours.mainText};
    border: ${({ theme, error }) =>
      error && `1px solid ${theme.colours.error}`};

    &:hover {
      border-color: ${({ theme }) => theme.colours.haloAccent};
    }
  }

  & .Select__control--is-focused {
    border-color: ${({ theme }) => theme.colours.haloAccent};
    color: ${({ theme }) => theme.colours.mainText};
  }

  & .Select__menu {
    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => theme.colours.mainText};
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borders.radius};
    width: fit-content;
  }

  & .Select__multi-value {
    background-color: ${({ theme }) => theme.colours.haloAccent};
    color: ${({ theme }) => theme.colours.white};
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  & .Select__multi-value__label {
    background-color: ${({ theme }) => theme.colours.haloAccent};
    color: ${({ theme }) => theme.colours.white};
    border-radius: ${({ theme }) => theme.borders.radius};
    padding: ${({ theme }) => theme.spacing.paddingXSM}
      ${({ theme }) => theme.spacing.paddingSM};
  }

  & .Select__multi-value__remove {
    background-color: ${({ theme }) => theme.colours.haloAccent};
    border-top-right-radius: ${({ theme }) => theme.borders.radius};
    border-bottom-right-radius: ${({ theme }) => theme.borders.radius};
    display: ${({ isDisabled }) => (isDisabled ? "none" : "flex")};
  }

  & .Select__option {
    background-color: ${({ theme }) => theme.colours.background};
    cursor: pointer;
  }

  & .Select__option--is-focused {
    background-color: ${({ theme }) => theme.colours.haloAccent};
    color: ${({ theme }) => theme.colours.white};
  }

  & .Select__option--is-selected {
    background-color: ${({ theme }) => theme.colours.haloAccent};
    color: ${({ theme }) => theme.colours.white};
  }

  & .Select__indicators {
    display: ${({ isDisabled }) => (isDisabled ? "none" : "flex")};
  }

  & .Select__placeholder {
    color: ${({ theme }) => theme.colours.secondaryText};
  }
`;

export const StyledMainContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
  flex-direction: ${({ isNotDesktop }) => (isNotDesktop ? "column" : "row")};
  align-items: ${({ isNotDesktop }) => isNotDesktop && "center"};
  justify-content: ${({ isNotDesktop }) => isNotDesktop && "center"};
  gap: ${({ isNotDesktop, theme }) => isNotDesktop && theme.spacing.padding};
`;

export const HighlightingMainContainer = styled(StyledMainContainer)`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colours.foreground};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colours.foreground};
  }
`;

export const ReportColumnContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colours.foreground};
  }

  div {
    &:nth-child(1) {
      flex-grow: 0;
      flex-basis: 33%;
    }

    &:nth-child(2) {
      flex-grow: 0;
      flex-basis: 45%;
    }

    &:nth-child(3) {
      flex-grow: 0;
      flex-basis: 26%;
    }

    &:nth-child(4) {
      flex-grow: 0;
      flex-basis: 15%;
    }

    &:nth-child(5) {
      flex-grow: 0;
      flex-basis: 25%;
    }
  }
`;

export const ReportConfigContainer = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  .Field {
    &:nth-child(1) {
      flex-grow: 0;
      flex-basis: 25%;
    }

    &:nth-child(2) {
      flex-grow: 0;
      flex-basis: 35%;
    }

    &:nth-child(3) {
      flex-grow: 0;
      flex-basis: 20%;
    }

    &:nth-child(4) {
      flex-grow: 0;
      flex-basis: 15%;
    }

    &:nth-child(5) {
      flex-grow: 0;
      flex-basis: 25%;
    }

    &:nth-child(6) {
      flex-grow: 0;
      flex-basis: 35%;
    }
  }
`;

export const ReportColumnHeader = styled.div`
  color: ${({ theme }) => theme.colours.mainText};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const ReportNameField = styled.div`
  color: ${({ theme }) => theme.colours.mainText};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const ReportLabelsField = styled.div`
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const ReportScheduleField = styled.div`
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const ReportStartDateField = styled.div`
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const ReportEmailsLabel = styled.div`
  color: ${({ theme }) => theme.colours.mainText};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const ReportEmailsField = styled.div`
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const ReadOnlySelect = styled(StyledSelect)`
  & .Select__multi-value__remove {
    display: none;
  }

  & .Select__indicators {
    display: none;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  > svg {
    width: 35px;
    height: 35px;
  }
  display: flex;
  @media (min-width: 600px) {
    display: none;
  }
  padding-right: ${({ theme }) => theme.spacing.padding};
  word-break: break-word;
`;

export const StyledTitlesContainer = styled.div`
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.colours.foreground};
  margin-bottom: 20px;
  padding-bottom: 16px;
  justify-content: space-between;

  div {
    font-size: ${({ theme }) => theme.fontSize.regular};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }

  .column-actions {
    @media (min-width: 600px) {
      min-width: 110px;
    }
  }
`;

export const ReportFieldLabel = styled.label`
  color: ${({ theme }) => theme.colours.mainText};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: none;
  margin-bottom: ${({ theme }) => theme.spacing.padding};
`;

export const StyledCard = styled.div``;

export const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  border-top-left-radius: 0;
  @media (min-width: 900px) {
    width: 100%;
  }
`;

export const StyledLabel = styled.label`
  text-align: left;
  display: ${({ hideLabels }) => (hideLabels && "none") || "block"};
  margin-bottom: ${({ theme }) => theme.spacing.padding};
`;

export const StyledField = styled.input`
  width: 100%;
  appearance: none;
  @media all and (-ms-high-contrast: none) {
    & {
      box-sizing: content-box;
    }
  }

  ${({ theme }) => css`
    padding: ${theme.spacing.padding};
    background: ${theme.colours.foreground};
    height: ${theme.sizing.btnHeight};
    line-height: inherit;
    border: ${({ theme }) => `1px solid ${theme.colours.background}`};
    border-radius: ${theme.borders.radius};
    color: ${theme.colours.mainText};
    border-color: ${({ theme, invalid }) => invalid && theme.colours.error};

    &::-moz-placeholder {
      /* Firefox 19+ */
      line-height: 0;
    }
  `};
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colours.error};
`;

export const RadioGroupContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
`;
