import { Modal } from "../../modal";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../../context/modal/modal.provider";
import { useProjectLabels } from "../../../../context/projectLabels/projectLabels.provider";
import { useEffect, useState } from "react";
import { Loading } from "../../../loading/loading";
import { TextArea } from "../../../textArea/textArea";
import { CreatableSelect } from "../../../select/creatableSelect";
import { RadioGroup } from "../../../Input/radioGroup";
import { Button } from "../../../Button/Button";
import {
  StyledMinusIcon,
  StyledPlusIcon,
} from "../../../../views/releasesView/releasesView.styles";
import { PriorityAndImpactContainer } from "./createManualRunModal.styles";
import { useCreateManualRun } from "../../../../views/runView/testevolveManual/hooks/run/useCreateRun";
import { useNotifications } from "../../../../context/hooks";

export const CreateManualRunModal = ({ getLabels }) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { createManualRun, createManualRunSucess } = useCreateManualRun();
  const { projectLabels, getProjectLabels } = useProjectLabels();

  const [label, setLabel] = useState(null);
  const [impact, setImpact] = useState(null);
  const [priority, setPriority] = useState(null);
  const [description, setDescription] = useState(null);
  const [priorityAndImpact, setPriorityAndImpact] = useState(false);
  const [error, setError] = useState(false);
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (!projectLabels) getProjectLabels();
  }, []);

  useEffect(() => {
    if (!priorityAndImpact) {
      setImpact(null);
      setPriority(null);
    }
  }, [priorityAndImpact]);

  useEffect(() => {
    if (createManualRunSucess) {
      if (getLabels) getLabels();
      closeModal();
    }
  }, [createManualRunSucess]);

  const noLabel = () => {
    setError(true);
    createNotification(
      types.ERROR,
      t("runView.testevolveexploratory.createRun.noLabel")
    );
  };

  return (
    <Modal
      title={t("runView.testevolveexploratory.createRun.title")}
      primary={{
        text: t("runView.testevolveexploratory.createRun.createRun"),
        onClick: () =>
          !label || !label?.length
            ? noLabel()
            : createManualRun({
                label,
                description,
                ...{
                  ...(priorityAndImpact
                    ? { impact: Number(impact), probability: Number(priority) }
                    : {}),
                },
              }),
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      {" "}
      {!projectLabels ? (
        <Loading />
      ) : (
        <>
          <CreatableSelect
            error={error}
            isClearable
            options={projectLabels}
            setter={setLabel}
            label={t("runView.testevolveexploratory.createRun.label")}
          />
          <TextArea
            label="Description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            placeholder={t(
              "runView.testevolveexploratory.createRun.descriptionPlaceholder"
            )}
          />
          <Button fitContent onClick={() => setPriorityAndImpact(e => !e)}>
            {priorityAndImpact ? <StyledMinusIcon /> : <StyledPlusIcon />}{" "}
            {t("runView.testevolveexploratory.createRun.probabilityAndImpact")}
          </Button>
          {priorityAndImpact ? (
            <PriorityAndImpactContainer>
              <RadioGroup
                showFirst
                showLast
                prefix={t(
                  "runView.testevolveexploratory.createRun.probability"
                )}
                label={t("runView.testevolveexploratory.createRun.probability")}
                options={[1, 2, 3]}
                setter={setPriority}
                value={priority}
              />
              <RadioGroup
                showFirst
                showLast
                prefix={t("runView.testevolveexploratory.createRun.impact")}
                label={t("runView.testevolveexploratory.createRun.impact")}
                options={[1, 2, 3]}
                setter={setImpact}
                value={impact}
              />
              <p>
                {t("runView.testevolveexploratory.createRun.riskScore")}{" "}
                {priority * impact}
              </p>
            </PriorityAndImpactContainer>
          ) : null}
        </>
      )}
    </Modal>
  );
};
