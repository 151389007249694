import { useTranslation } from "react-i18next";
import { NodeCard } from "../../common/NodeCard";
import { useNodeView } from "../../hooks/useNodeView";
import {
  PROVIDERS,
  RUN_STATUSES,
} from "../../../../static/constants/constants";
import {
  BigNumberContainer,
  BigNumbersContainer,
  Container,
  SameRowContainer,
  SmallNumberContainer,
  SmallNumbersContainer,
  SmallerNumberContainer,
  SmallerNumbersContainer,
  WordContainer,
} from "./nodeCardTestEvolveManual.styles";
import { ReactComponent as BugIcon } from "../../../../static/assets/icon-bug.svg";
import { ReactComponent as MapIcon } from "../../../../static/assets/icon-map.svg";
import { ReactComponent as PencilIcon } from "../../../../static/assets/icon-pencil.svg";
import { useLatestRun } from "../../../runView/testevolveManual/hooks/run/useLatestRun";

export const NodeCardTestEvolveManual = ({
  run,
  getLabels,
  onRelease,
  frozen,
  isShared,
}) => {
  const { t } = useTranslation();
  const { nodeId } = useNodeView();
  const { startTime, runProps } = useLatestRun(run);
  const runStatus =
    t(`runCard.testevolve.status.${run.status.toLowerCase()}`) ||
    t("runCard.testevolveexploratory.n/a");

  return (
    <NodeCard
      data-test="test-evolve-card"
      type={run.category.replace(".", "")}
      nodeId={nodeId}
      run={run}
      startTime={startTime}
      label={run.label}
      getLabels={getLabels}
      runProps={runProps}
      onRelease={onRelease}
      frozen={frozen}
      isShared={isShared}
      provider={PROVIDERS.MANUAL}
      runStatus={runStatus}
      status={run.status}
      hideApproval={run.status.toLowerCase() !== RUN_STATUSES.CLOSED}
      hideFreeze={run.status.toLowerCase() !== RUN_STATUSES.CLOSED}
      noRunProperties
    >
      <Container>
        <BigNumbersContainer>
          <BigNumberContainer>
            <h1>{run?.total_sessions || 0}</h1>
            <h2>{t("runCard.testevolveexploratory.sessions")}</h2>
          </BigNumberContainer>
          <BigNumberContainer>
            <h1>
              {run.total_bugs + run.total_notes + run.total_observations || 0}
            </h1>
            <h2>{t("runCard.testevolveexploratory.entries")}</h2>
          </BigNumberContainer>
        </BigNumbersContainer>
        <SmallNumbersContainer>
          <SameRowContainer>
            <SmallNumberContainer>
              <PencilIcon />
              <h1>{run.total_notes || 0}</h1>
              <h2>{t("runCard.testevolveexploratory.notes")}</h2>
            </SmallNumberContainer>
            <SmallNumberContainer>
              <BugIcon />
              <h1>{run.total_bugs || 0}</h1>
              <h2>{t("runCard.testevolveexploratory.bugs")}</h2>
            </SmallNumberContainer>
          </SameRowContainer>
          <SmallNumberContainer>
            <MapIcon />
            <h1>{run.total_observations || 0}</h1>
            <h2>{t("runCard.testevolveexploratory.observations")}</h2>
          </SmallNumberContainer>
        </SmallNumbersContainer>
        <SmallerNumbersContainer>
          <WordContainer>
            <SmallerNumberContainer probact={Number(run.impact)}>
              <h2>{run.impact || t("runCard.testevolveexploratory.n/a")}</h2>
            </SmallerNumberContainer>
            <p>{t("runCard.testevolveexploratory.impact")}</p>
          </WordContainer>
          <WordContainer>
            <SmallerNumberContainer probact={Number(run.probability)}>
              <h2>
                {run.probability || t("runCard.testevolveexploratory.n/a")}
              </h2>
            </SmallerNumberContainer>
            <p>{t("runCard.testevolveexploratory.probability")}</p>
          </WordContainer>
          <WordContainer>
            <SmallerNumberContainer risk={Number(run.risk)}>
              <h2>{run.risk || t("runCard.testevolveexploratory.n/a")}</h2>
            </SmallerNumberContainer>
            <p>{t("runCard.testevolveexploratory.risk")}</p>
          </WordContainer>
        </SmallerNumbersContainer>
      </Container>
    </NodeCard>
  );
};
