import { isEqual } from "lodash";
import { memo, useEffect, useState } from "react";
import { Sessions } from "./sessions/sessions";
import { useEditRun } from "./hooks/run/useEditRun";
import { RunDetails } from "../runDetails/runDetails";
import { useApp } from "../../../context/app/app.provider";
import { Container } from "./testevolveManualRunView.styles";
import { CurrentSession } from "./currentSession/currentSession";
import { ManualRunDetails } from "./manualRunDetails/manualRunDetails";
import { TestEvolveExploratoryRunHistory } from "./runHistory/runHistory";
import {
  WidgetContainer,
  HorizontalWidgetContainer,
} from "../testEvolve/testEvolveRunView.styles";
import { RUN_STATUSES } from "../../../static/constants/constants";
import { useQueryParams } from "../../../context/hooks";

const SESSION = "session";

export const TestEvolveManualRunViewUnMemo = ({
  run,
  getRun,
  release,
  isShared,
  scrollRef,
  runDetails,
  nodeDetails,
  setIsPolling,
  getRunDetails,
}) => {
  const { getParam } = useQueryParams();
  const sessionString = getParam(SESSION);
  let session;
  if (sessionString) session = Number(sessionString);

  const { isNotDesktop } = useApp();
  const { editRun } = useEditRun(run.id, getRun);
  const [currentSessionId, setCurrentSessionId] = useState(
    session || run?.sessions[0]?.id || null
  );

  useEffect(() => {
    const sessionString = getParam(SESSION);
    if (!sessionString) return;

    if (sessionString && Number(sessionString) !== currentSessionId)
      setCurrentSessionId(Number(sessionString));
  }, [location, location?.search]);

  if (!run) return null;
  return (
    <Container ref={scrollRef} isShared={isShared}>
      <WidgetContainer isNotDesktop={isNotDesktop}>
        <RunDetails
          isShared={isShared}
          name={run.label}
          runApproved={run.approved}
          runId={run.id}
          run={run}
          nodeDetails={nodeDetails}
          runDetails={runDetails}
          setIsPolling={setIsPolling}
          release={release}
          hideFreeze={run.status.toLowerCase() !== RUN_STATUSES.CLOSED}
        />
        <ManualRunDetails run={run} editField={editRun} getRun={getRun} />
        <HorizontalWidgetContainer>
          <Sessions
            runId={run.id}
            status={run.status}
            sessions={run?.sessions}
            isSelected={currentSessionId}
            getRun={getRun}
            setSession={setCurrentSessionId}
            getRunDetails={getRunDetails}
          />
          <CurrentSession
            runClosed={run.status.toLowerCase() === RUN_STATUSES.CLOSED}
            getRun={getRun}
            getRunDetails={getRunDetails}
            status={run.status}
            setIsPolling={setIsPolling}
            session={
              run?.sessions?.find(session => session.id === currentSessionId) ||
              run?.sessions[0]
            }
          />
        </HorizontalWidgetContainer>
      </WidgetContainer>
      <TestEvolveExploratoryRunHistory
        runDetails={runDetails}
        getRunDetails={getRunDetails}
      />
    </Container>
  );
};

export const TestEvolveManualRunView = memo(
  TestEvolveManualRunViewUnMemo,
  (p, c) =>
    isEqual(p.run, c.run) &&
    isEqual(p.release, c.release) &&
    isEqual(p.isShared, c.isShared) &&
    isEqual(p.runDetails, c.runDetails) &&
    isEqual(p.nodeDetails, c.nodeDetails)
);
