import React from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../../../router/routes";
import {
  Breadcrumbs,
  BreadcrumbLink,
} from "../../nodeView/nodeDetails/nodeDetails.styles";
import { useParams, useLocation } from "react-router-dom";
import {
  RunSharingTokenProvider,
  useRunSharingToken,
} from "../hooks/sharedRunView/useRunSharingToken";
import { useRunExport } from "../hooks/runExport/useRunExport";
import {
  ButtonContainer,
  ButtonIcon,
  LabelContainer,
  LabelEditButton,
  RunDetailsContainer,
  InfoContainer,
  ReleasesContainer,
} from "./runDetails.styles";
import { getLabelFromURL } from "../../../utils/labelFromURL/labelfromURL";
import { useTheme } from "../../../context/theme/theme.provider";
import { useTheme as useStyles } from "styled-components";
import { useApp } from "../../../context/app/app.provider";
import { approvalUtils } from "../hooks/approval/approvalUtils";
import { useApproveRun } from "../hooks/approval/useApproveRun";
import { Button } from "../../../components";
import { Spacer } from "../../runReportView/components/bug.styles";
import { ReactComponent as DownloadIcon } from "../../../static/assets/icon-download.svg";
import { ReactComponent as ShareIcon } from "../../../static/assets/icon-share.svg";
import { SharedRunDetails } from "./sharedRunDetails";
import { FreezeRunMenu } from "../components/freezeRun/freezeRunMenu";
import { useModal } from "../../../context/modal/modal.provider";
import { useProject } from "../../../context/project/project.provider";
import { RUN_STATUSES } from "../../../static/constants/constants";
import { Tooltip } from "../../../components/tooltip/tooltip";

export const RunDetailsContent = ({
  runApproved,
  name,
  nodeDetails,
  runDetails,
  run,
  release,
  hideFreeze,
}) => {
  const { t } = useTranslation();
  const { runType } = useParams();
  const { isDesktop } = useApp();
  const { isDark } = useTheme();
  const theme = useStyles();
  const label = getLabelFromURL();
  const { openExportModal } = useRunExport();
  const { approvalIcon, getApprovedStatus, formatApproved } = approvalUtils();
  const { openApproveModal } = useApproveRun();
  const { openModal, MODALS } = useModal();
  const { runSharingToken } = useRunSharingToken();
  const location = useLocation();
  const { project } = useProject();

  const allowApproval = ![RUN_STATUSES.OPEN, RUN_STATUSES.IN_PROGRESS].includes(
    run.status?.toLowerCase()
  );

  const openLabelModal = (e, runLabel, runCategory) => {
    e?.syntheticEvent?.stopPropagation();
    e?.syntheticEvent?.preventDefault();

    openModal(MODALS.UPDATE_LABEL, { run, runLabel, runCategory });
  };

  const capitaliseFirstLetter = string => {
    return (
      string.charAt(0).toUpperCase() +
      string.slice(1).toLowerCase().replace("_", " ")
    );
  };

  const fromHome = location.state?.fromHome;

  return (
    <RunDetailsContainer>
      <InfoContainer>
        <Breadcrumbs>
          {release ? (
            <React.Fragment key={`fragment-${release.id}`}>
              {t("navigation.releases")} /
              <BreadcrumbLink
                key={`breadcrumb-${release.id}`}
                to={`/release/${release.id}`}
                data-test={`breadcrumbs-link-${release.id}`}
              >
                {release.name}
              </BreadcrumbLink>
            </React.Fragment>
          ) : nodeDetails ? (
            `${nodeDetails.breadcrumbs}`.split("/").map((id, index) => (
              <React.Fragment key={`fragment-${id}`}>
                /
                <BreadcrumbLink
                  key={`breadcrumb-${id}`}
                  to={`/node/${nodeDetails.path.split("/")[index]}`}
                  data-test={`breadcrumbs-link-${id}`}
                >
                  {id}
                </BreadcrumbLink>
              </React.Fragment>
            ))
          ) : fromHome ? (
            <BreadcrumbLink
              key={"unassigned"}
              to={`${routes.HOME.path}?project=${project.id}`}
              data-test={"breadcrumbs-link-home"}
            >
              {t("navigation.home")}
            </BreadcrumbLink>
          ) : (
            <BreadcrumbLink
              key={"unassigned"}
              to={routes.UNASSIGNED_LABELS.path}
              data-test={"breadcrumbs-link-unassigned"}
            >
              {t("navigation.unassignedLabels")}
            </BreadcrumbLink>
          )}
          /
          <BreadcrumbLink
            key="te"
            to={window.location.pathname}
            data-test={"breadcrumbs-link"}
          >
            {decodeURI(label)}
          </BreadcrumbLink>
        </Breadcrumbs>
        <LabelContainer>
          <h1 data-test="title">{name}</h1>
          <LabelEditButton
            onClick={e => openLabelModal(e, name, runType)}
            title={t("runView.updateLabel.modalTitle")}
          />
        </LabelContainer>
        <p data-test="title">{t(`runView.runDetails.${runType}`)}</p>
      </InfoContainer>
      {runApproved && formatApproved(runApproved) ? (
        <Button
          dark={isDark}
          width="fit-content"
          desktop={isDesktop}
          data-test="approve-run-button"
          padding={theme.spacing.paddingSM}
          title={formatApproved(runApproved)}
          onClick={e => openApproveModal(e, run)}
        >
          {approvalIcon(runApproved)}
          {getApprovedStatus(runApproved)}
        </Button>
      ) : (
        <Tooltip
          disabled={allowApproval}
          content={t("runView.runDetails.cannotApprove", {
            status: capitaliseFirstLetter(run.status),
          })}
        >
          <div>
            <Button
              dark={isDark}
              width="fit-content"
              desktop={isDesktop}
              disabled={!allowApproval}
              data-test="approve-run-button"
              padding={theme.spacing.paddingSM}
              onClick={e => openApproveModal(e, run)}
            >
              {t("runHistory.approveRun")}
            </Button>
          </div>
        </Tooltip>
      )}
      <ReleasesContainer>
        <FreezeRunMenu
          run={run}
          runLabel={run.label}
          hideFreeze={hideFreeze}
          runReleases={run?.releases}
        />
      </ReleasesContainer>
      {isDesktop ? <Spacer /> : null}
      <ButtonContainer>
        <ButtonIcon
          title="Download"
          data-test="download-btn"
          onClick={() => openExportModal(run)}
        >
          <DownloadIcon />
        </ButtonIcon>
        <ButtonIcon
          title="Share"
          data-test="share-btn"
          onClick={() =>
            openModal(MODALS.SHARE_RUN_VIEW, { runDetails, runSharingToken })
          }
        >
          <ShareIcon />
        </ButtonIcon>
      </ButtonContainer>
    </RunDetailsContainer>
  );
};

export const RunDetails = props => {
  if (props.isShared)
    return <SharedRunDetails name={props?.name} run={props?.run} />;

  return (
    <RunSharingTokenProvider>
      <RunDetailsContent {...props} />
    </RunSharingTokenProvider>
  );
};
