import { useTranslation } from "react-i18next";
import { useReleases } from "../../../context/releases/releases.provider";
import { ReleaseCard } from "../../releasesView/releaseCard/releaseCard";
import {
  EmptyContentContainer,
  HorizontalContainer,
  SectionContainer,
  SectionContent,
  SectionDetailsContainer,
  SectionTitle,
} from "../home.styles";
import image from "../../../static/assets/ship_image.png";
import { Spacer } from "../../runReportView/components/bug.styles";
import { usePermissions } from "../../../context/hooks";
import { useModal } from "../../../context/modal/modal.provider";
import { Button } from "../../../components";
import { StyledPlusIcon } from "../../releasesView/releasesView.styles";
import { useGetAllOpenReleases } from "../../../context/releases/getAllOpenReleases";
import { useEffect } from "react";
import { FakeMinimalRunCard } from "../../../components/runCard/minimalRunCards/minimalRunCard.styles";

export const UpcomingReleases = () => {
  const { t } = useTranslation();
  const { allOpenReleases } = useReleases();
  const { isAdmin } = usePermissions();
  const { openModal, MODALS } = useModal();
  const { getAllOpenReleases, loading } = useGetAllOpenReleases();

  useEffect(() => {
    getAllOpenReleases();
  }, []);

  return (
    <SectionContainer>
      <HorizontalContainer>
        <SectionDetailsContainer>
          <SectionTitle>{t("projectHome.upcomingReleasesTitle")}</SectionTitle>
          <p>{t("projectHome.upcomingReleasesText")}</p>
        </SectionDetailsContainer>
        <Spacer />
        {isAdmin() ? (
          <Button
            onClick={() => openModal(MODALS.CREATE_RELEASE)}
            data-test="create-release-btn"
          >
            <StyledPlusIcon />
            {t("releases.releasesView.newRelease")}
          </Button>
        ) : null}
      </HorizontalContainer>
      <SectionContent tabIndex={0}>
        {loading && !allOpenReleases.length ? (
          [...Array(5)].map((_, i) => (
            <FakeMinimalRunCard key={`fake-in-progress-${i}`} />
          ))
        ) : allOpenReleases && allOpenReleases.length ? (
          allOpenReleases
            .slice(0, 5)
            .map(release => (
              <ReleaseCard
                key={`upcoming-release-${release.id}`}
                release={release}
              />
            ))
        ) : (
          <EmptyContentContainer>
            <img src={image} alt="No Runs to show" />
            <p>{t("projectHome.upcomingReleasesEmpty")}</p>
          </EmptyContentContainer>
        )}
      </SectionContent>
    </SectionContainer>
  );
};
