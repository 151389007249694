import { useRef, useState } from "react";
import {
  Attachment,
  AttachmentButton,
  AttachmentsContainer,
  ButtonContainer,
  CrossIcon,
  EntryRow,
} from "../testevolveManualRunView.styles";
import { Button } from "../../../../components";
import { EntryType } from "./entryType";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../nodeView/common/NodeCard.styles";
import {
  ENTRY_TYPES,
  MENU_STATE,
} from "../../../../static/constants/constants";
import { useClick, useMenuState } from "@szhsin/react-menu";
import { ReactComponent as BugIcon } from "../../../../static/assets/icon-bug.svg";
import { ReactComponent as MapIcon } from "../../../../static/assets/icon-map.svg";
import { ReactComponent as PencilIcon } from "../../../../static/assets/icon-pencil.svg";
import { ReactComponent as AttachmentIcon } from "../../../../static/assets/icon-attachment.svg";
import { Spacer } from "../../../../components/NavigationPane/navigationPane.styles";
import { useTranslation } from "react-i18next";
import { RichTextEditor } from "../../../../components/richTextEditor/richTextEditor";

const TypeMenu = ({ setType, type }) => {
  const menuRef = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const anchorProps = useClick(menuState.state, toggleMenu);
  const menuOpen = menuState.state === MENU_STATE.OPEN;

  return (
    <>
      <EntryType
        type={type}
        isOpen={menuOpen}
        entryRef={menuRef}
        anchorProps={anchorProps}
        onClick={() => toggleMenu(!menuOpen)}
        isMenu
      />
      <StyledMenu
        {...menuState}
        transition
        anchorRef={menuRef}
        onClose={() => {
          toggleMenu(false);
        }}
      >
        <StyledMenuItem
          value={ENTRY_TYPES.NOTE}
          onClick={e => setType(e?.value)}
        >
          <PencilIcon />
          <p>{ENTRY_TYPES.NOTE.toLowerCase()}</p>
        </StyledMenuItem>
        <StyledMenuItem
          value={ENTRY_TYPES.OBSERVATION}
          onClick={e => setType(e?.value)}
        >
          <MapIcon />
          <p>{ENTRY_TYPES.OBSERVATION.toLowerCase()}</p>
        </StyledMenuItem>
        <StyledMenuItem
          value={ENTRY_TYPES.BUG}
          onClick={e => setType(e?.value)}
        >
          <BugIcon />
          <p>{ENTRY_TYPES.BUG.toLowerCase()}</p>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

export const NewEntryForm = ({
  close,
  entry,
  addEntry,
  editEntry,
  deleteEntry,
  isAdmin,
  isOwnSession,
  loading,
}) => {
  const { t } = useTranslation();
  const [isSure, setIsSure] = useState(false);

  const [newEntry, setNewEntry] = useState(
    entry
      ? entry
      : {
          content: null,
          type: ENTRY_TYPES.NOTE,
          attachments: entry?.attachments ? entry.attachments : [],
        }
  );

  const inputFile = useRef(null);

  const onButtonClick = () => inputFile.current.click();

  const addAttachment = async e => {
    const reader = new FileReader();

    reader.onload = async readerE => {
      const fileName = e.target.value?.split("\\");
      const sanitisedFileName = fileName[fileName.length - 1];

      const blob = new Blob([readerE.target.result]);

      const attachment = { file_name: sanitisedFileName, blob };

      setNewEntry(entry => ({
        ...entry,
        attachments: entry?.attachments
          ? [...entry.attachments, attachment]
          : [attachment],
      }));
    };

    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const filterAttachment = e =>
    setNewEntry(entry => ({
      ...entry,
      attachments: entry.attachments.filter(att => att !== e),
    }));

  const handleSubmit = () => {
    if (addEntry) addEntry(newEntry);
    if (editEntry) editEntry(newEntry, entry.id, entry);
  };

  return (
    <EntryRow entry={!!entry}>
      {entry ? null : <p>{t("runView.testevolveexploratory.newEntry")}</p>}
      <TypeMenu
        type={newEntry?.type.toUpperCase() || ENTRY_TYPES.NOTE}
        setType={e => setNewEntry(entry => ({ ...entry, type: e }))}
      />
      <RichTextEditor
        setter={e => setNewEntry(ne => ({ ...ne, content: e }))}
        initial={newEntry.content}
        readOnly={!isOwnSession || loading}
      />
      <input
        disabled={loading}
        multiple
        id="file"
        type="file"
        ref={inputFile}
        onChange={addAttachment}
        style={{ display: "none" }}
        onClick={() => (inputFile.current.value = null)}
      />
      <AttachmentsContainer>
        {isOwnSession ? (
          <AttachmentButton onClick={onButtonClick}>
            <AttachmentIcon />
            {t("runView.testevolveexploratory.addAttachment")}
          </AttachmentButton>
        ) : null}
        {newEntry?.attachments?.map(att => (
          <Attachment danger key={att}>
            <p>{att.file_name}</p>
            {isOwnSession ? (
              <CrossIcon onClick={() => filterAttachment(att)} />
            ) : null}
          </Attachment>
        ))}
      </AttachmentsContainer>
      <ButtonContainer>
        <Button secondary onClick={close} disabled={loading}>
          {t("btnCancel")}
        </Button>
        {isOwnSession ? (
          <Button
            accent
            loader
            loading={loading}
            onClick={handleSubmit}
            disabled={!newEntry?.content?.length}
          >
            {t("btnSave")}
          </Button>
        ) : null}
        {deleteEntry && (isAdmin || isOwnSession) && !loading ? (
          <>
            <Spacer />
            {isSure ? <p>{t("areYouSure")}</p> : null}
            <Button
              danger
              onClick={
                isSure ? () => deleteEntry(entry.id) : () => setIsSure(true)
              }
            >
              {t("btnDelete")}
            </Button>
          </>
        ) : null}
      </ButtonContainer>
    </EntryRow>
  );
};
