import { useTranslation } from "react-i18next";
import { theme } from "../../../../../theme/theme";
import { useTime } from "../../../../../context/hooks/time/useTime";
import { RUN_PROPERTIES } from "../../../../../static/constants/constants";

export const useLatestRun = run => {
  const { t } = useTranslation();
  const { getReadableTimeDifference } = useTime();

  const totalViolations =
    run.axe_summary.critical +
    run.axe_summary.serious +
    run.axe_summary.moderate +
    run.axe_summary.minor;

  const violationsChartData = [
    {
      name: t("runCard.testevolveaccessibility.violations.critical"),
      count: run.axe_summary.critical,
    },
    {
      name: t("runCard.testevolveaccessibility.violations.serious"),
      count: run.axe_summary.serious,
    },
    {
      name: t("runCard.testevolveaccessibility.violations.moderate"),
      count: run.axe_summary.moderate,
    },
    {
      name: t("runCard.testevolveaccessibility.violations.minor"),
      count: run.axe_summary.minor,
    },
  ];

  const scenarioStatuses = {
    passed: run.passed,
    failed: run.failed,
    error: run.error,
    skipped: run.skipped,
  };

  const totalScenarios =
    (run.failed + run.pending + run.passed + run.error) / 100;

  const scenariosChartData = [
    {
      name: t("runCard.testevolveaccessibility.passed"),
      value: run.passed / totalScenarios,
      color: theme.gradients.passing_end,
    },
    {
      name: t("runCard.testevolveaccessibility.failed"),
      value: run.failed / totalScenarios,
      color: theme.gradients.error_start,
    },
    {
      name: t("runCard.testevolveaccessibility.error"),
      value: run.error / totalScenarios,
      color: theme.gradients.critical_end,
    },
    {
      name: t("runCard.testevolveaccessibility.skipped"),
      value: run.skipped / totalScenarios,
      color: theme.gradients.skipped_start,
    },
  ];

  const scenarios = run?.features?.map(feature => feature.scenarios).flat();

  const failedScenarios = scenarios?.filter(
    scenario => scenario.status === "failed"
  );
  const errorScenarios = scenarios?.filter(
    scenario => scenario.status === "error"
  );
  const passedScenarios = scenarios?.filter(
    scenario => scenario.status === "passed"
  );
  const skippedScenarios = scenarios?.filter(
    scenario => scenario.status === "skipped"
  );

  const runProps = RUN_PROPERTIES[run.test_type]?.map(prop => [
    run.run_properties[prop],
    prop,
  ]);

  return {
    run,
    runProps,
    errorScenarios,
    totalViolations,
    failedScenarios,
    passedScenarios,
    skippedScenarios,
    scenarioStatuses,
    status: run.status,
    scenariosChartData,
    violationsChartData,
    checksPerformed: run.axe_summary.checksPerformed,
    accessibilityStandards: run.axe_summary.standards,
    runStatus:
      t(`runCard.testevolveaccessibility.status.${run.status}`) || "N/A",
    timeAgo:
      `${getReadableTimeDifference(run.finish_time ?? run.updated_time)} ${t(
        "time.ago"
      )}` || "N/A",
  };
};
