import React from "react";
import { useTranslation } from "react-i18next";
import {
  XAxis,
  YAxis,
  Tooltip,
  Line,
  LineChart,
  CartesianGrid,
  Label,
  AreaChart,
  Area,
  Treemap,
  Legend,
} from "recharts";
import {
  Container,
  StyledPieChart,
  StyledPieChartLighthouse,
  Value,
  GraphContainer,
  ToolTipDiv,
  StyledLabel,
  LighthouseContainer,
  LighthouseValue,
  StyledLighthouseLabel,
  InsideLighthouseContainer,
  StyledPieChartVisual,
  VisualContainer,
  VisualValue,
  VisualLabel,
} from "./graph.styles";
import { light, dark } from "../../theme/theme";
import { useTheme } from "../../context/theme/theme.provider";
import { useApp } from "../../context/app/app.provider";
import { uuid } from "../../utils/uuid/uuid";

export const Gauge = ({ data, passed, containerHeight }) => {
  const { t } = useTranslation();

  return (
    <Container data-test="gauge" height={containerHeight}>
      <StyledPieChart
        rounded
        data={data.filter(d => d.value !== 0)}
        lineWidth={12}
        startAngle={150}
        lengthAngle={240}
        totalLabel={100}
        paddingAngle={10}
        animate
      ></StyledPieChart>
      <Value data-test="passing-val">{passed}%</Value>
      <StyledLabel>{t("passing")}</StyledLabel>
    </Container>
  );
};

export const VisualGauge = ({ data, passed }) => {
  const { t } = useTranslation();

  return (
    <VisualContainer data-test="visual-gauge">
      <StyledPieChartVisual
        rounded
        data={data.filter(d => d.value !== 0)}
        lineWidth={12}
        startAngle={150}
        lengthAngle={240}
        totalLabel={100}
        paddingAngle={10}
        animate
      ></StyledPieChartVisual>
      <VisualValue data-test="passing-val">
        {passed}%<VisualLabel>{t("passing")}</VisualLabel>
      </VisualValue>
    </VisualContainer>
  );
};

export const LighthouseScoreGauge = ({ data, passed, large }) => {
  return (
    <LighthouseContainer data-test="lighthouse-gauge" large={large}>
      <InsideLighthouseContainer large={large}>
        <StyledPieChartLighthouse
          rounded
          data={data}
          lineWidth={12}
          startAngle={150}
          lengthAngle={240}
          totalLabel={100}
          paddingAngle={0}
          animate
          large={large}
        ></StyledPieChartLighthouse>
        <LighthouseValue large={large} data-test="score-val">
          {passed}
        </LighthouseValue>
      </InsideLighthouseContainer>
      <StyledLighthouseLabel large={large}>
        {data[0].name}
      </StyledLighthouseLabel>
    </LighthouseContainer>
  );
};

export const ScoreGauge = ({ data, passed, containerHeight }) => {
  return (
    <Container data-test="gauge" height={containerHeight}>
      <StyledPieChart
        rounded
        data={data}
        lineWidth={12}
        startAngle={150}
        lengthAngle={240}
        totalLabel={100}
        paddingAngle={0}
        animate
      ></StyledPieChart>
      <Value data-test="score-val">{passed}</Value>
      <StyledLabel top="-52%">{data[0].name}</StyledLabel>
    </Container>
  );
};

export const CheckLabelTreemap = ({ data }) => {
  const { theme } = useTheme();
  const themeObj = { light: light, dark: dark };

  return (
    <GraphContainer>
      <Treemap
        data={data}
        dataKey="checks"
        stroke={themeObj[theme].colours.mainText}
        fill={themeObj[theme].colours.runPropertyBackground}
      />
    </GraphContainer>
  );
};

export const DurationGraph = ({ data }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { isNotDesktop } = useApp();

  const themeObj = { light: light, dark: dark };

  const CustomTooltip = ({ payload }) => {
    if (!payload) {
      return <ToolTipDiv />;
    }

    const duration = data.filter(data => data.id === payload[0]?.payload?.id)[0]
      ?.duration;

    return (
      <ToolTipDiv>
        <p>
          {t("runHistory.runId")} {payload[0]?.payload.id}
        </p>
        <p>
          {duration} {t("runView.runTrends.minutes")}
        </p>
      </ToolTipDiv>
    );
  };

  const mobileMargin = { top: 10, right: 10, left: -40, bottom: 40 };
  const margin = { top: 10, right: 40, left: 20, bottom: 40 };

  return (
    <GraphContainer>
      <AreaChart
        data={data.reverse()}
        margin={isNotDesktop ? mobileMargin : margin}
      >
        <Tooltip content={<CustomTooltip />} />
        <YAxis type="number" domain={[0, "dataMax"]}>
          {isNotDesktop ? null : (
            <Label
              angle={270}
              offset={0}
              position="left"
              style={{ textAnchor: "middle" }}
              value={t("graphs.duration")}
            />
          )}
        </YAxis>
        <XAxis dataKey="id">
          <Label
            offset={10}
            position="bottom"
            style={{ textAnchor: "middle" }}
            value={t("graphs.runId")}
          />
        </XAxis>
        <CartesianGrid vertical={false} />
        <Area
          strokeWidth={4}
          type="monotone"
          fillOpacity={1}
          dataKey="duration"
          stroke={themeObj[theme].colours.foreground}
          fill="url(#gradientInfo)"
        />
      </AreaChart>
    </GraphContainer>
  );
};

export const TestEvolveRunTrendsGraph = ({ data }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { isNotDesktop } = useApp();

  const themeObj = { light: light, dark: dark };

  const modifiedData = data.map(obj => ({
    ...obj,
    passFail: obj.passed + obj.failed,
    other: obj.undefined + obj.skipped + obj.pending,
  }));

  const CustomTooltip = ({ payload }) => {
    if (!payload) {
      return <ToolTipDiv />;
    }

    const failed = modifiedData.filter(
      data => data.id === payload[0]?.payload?.id
    )[0]?.failed;

    const other = modifiedData.filter(
      data => data.id === payload[0]?.payload?.id
    )[0]?.other;

    return (
      <ToolTipDiv>
        <p>
          {t("runHistory.runId")} {payload[0]?.payload.id}
        </p>
        {payload
          .filter(p => p.name !== "passFail")
          .map(p => (
            <p key={`p-${p.name}`}>
              {p.value} {t(`runView.testevolve.${p.name}`)}
            </p>
          ))}
        <p>
          {failed} {t("runView.testevolve.failed")}
        </p>
        <p>
          {other} {t("runView.testevolve.other")}
        </p>
      </ToolTipDiv>
    );
  };
  const mobileMargin = { top: 10, right: 10, left: -40, bottom: 40 };
  const margin = { top: 10, right: 40, left: 20, bottom: 40 };

  return (
    <GraphContainer>
      <AreaChart
        data={modifiedData.reverse()}
        margin={isNotDesktop ? mobileMargin : margin}
      >
        <Tooltip content={<CustomTooltip />} />
        <YAxis type="number" domain={[0, "dataMax"]}>
          {isNotDesktop ? null : (
            <Label
              angle={270}
              offset={0}
              position="left"
              style={{ textAnchor: "middle" }}
              value={t("graphs.scenarioCount")}
            />
          )}
        </YAxis>
        <XAxis dataKey="id">
          <Label
            offset={10}
            position="bottom"
            style={{ textAnchor: "middle" }}
            value={t("graphs.runId")}
          />
        </XAxis>
        <CartesianGrid vertical={false} />
        <Area
          strokeWidth={4}
          type="monotone"
          fillOpacity={1}
          dataKey="total_scenarios"
          stroke={themeObj[theme].colours.foreground}
          fill="url(#gradientTotal)"
        />
        <Area
          strokeWidth={4}
          type="monotone"
          fillOpacity={1}
          dataKey="passFail"
          stroke={themeObj[theme].colours.foreground}
          fill="url(#gradientFail)"
        />
        <Area
          strokeWidth={4}
          type="monotone"
          fillOpacity={1}
          dataKey="passed"
          stroke={themeObj[theme].colours.foreground}
          fill="url(#gradientPass)"
        />
      </AreaChart>
    </GraphContainer>
  );
};

export const NpmAuditRunTrendsGraph = ({ data }) => {
  const { t } = useTranslation();
  const modifiedData = data.map(entry => ({
    ...entry.data.vulnerabilities,
    id: entry.id,
  }));

  const graphUuid = uuid();

  const CustomTooltip = ({ payload }) => {
    if (!payload) {
      return <ToolTipDiv />;
    }

    const vulnerabilities = payload[0]?.payload;

    return (
      <ToolTipDiv>
        <p>
          {t("runHistory.runId")} {payload[0]?.payload.id}
        </p>
        <p>
          {vulnerabilities?.critical}{" "}
          {t("runCard.npmaudit.vulnerabilities.critical")}
        </p>
        <p>
          {vulnerabilities?.high} {t("runCard.npmaudit.vulnerabilities.high")}
        </p>
        <p>
          {vulnerabilities?.moderate}{" "}
          {t("runCard.npmaudit.vulnerabilities.moderate")}
        </p>
        <p>
          {vulnerabilities?.low} {t("runCard.npmaudit.vulnerabilities.low")}
        </p>
        <p>
          {vulnerabilities?.info} {t("runCard.npmaudit.vulnerabilities.info")}
        </p>
      </ToolTipDiv>
    );
  };

  return (
    <GraphContainer data-test="npm-audit-run-trends-graph">
      <LineChart
        data-test="npm-audit-run-trends-graph"
        data={modifiedData.reverse()}
        margin={{ top: 10, right: 30, left: 20, bottom: 40 }}
      >
        <Tooltip content={<CustomTooltip />} />
        <YAxis type="number" domain={[0, "dataMax"]}>
          <Label
            angle={270}
            offset={0}
            position="left"
            style={{ textAnchor: "middle" }}
            value={t("graphs.vulnerabilities")}
          />
        </YAxis>
        <XAxis dataKey="id">
          <Label
            offset={10}
            position="bottom"
            style={{ textAnchor: "middle" }}
            value={t("graphs.runId")}
          />
        </XAxis>
        <CartesianGrid vertical={false} />
        <Line
          strokeWidth={4}
          type="monotone"
          dataKey="critical"
          stroke={`url(#gradientCritical${graphUuid})`}
        />
        <Line
          strokeWidth={4}
          type="monotone"
          dataKey="high"
          stroke={`url(#gradientHigh${graphUuid})`}
        />
        <Line
          strokeWidth={4}
          type="monotone"
          dataKey="moderate"
          stroke={`url(#gradientModerate${graphUuid})`}
        />
        <Line
          strokeWidth={4}
          type="monotone"
          dataKey="low"
          stroke={`url(#gradientLow${graphUuid})`}
        />
        <Line
          strokeWidth={4}
          type="monotone"
          dataKey="info"
          stroke={`url(#gradientInfo${graphUuid})`}
        />
      </LineChart>
    </GraphContainer>
  );
};

export const TestEvolveAccessibilityRunTrendsGraph = ({ data }) => {
  const { t } = useTranslation();
  const { isNotDesktop } = useApp();
  const modifiedData = data.map(entry => ({
    ...entry.axe_summary,
    id: entry.id,
  }));

  const CustomTooltip = ({ payload }) => {
    if (!payload) {
      return <ToolTipDiv />;
    }

    const violations = payload[0]?.payload;

    return (
      <ToolTipDiv>
        <p>
          {t("runHistory.runId")} {payload[0]?.payload.id}
        </p>
        <p>
          {violations?.critical}{" "}
          {t("runView.testevolveaccessibility.violations.critical")}
        </p>
        <p>
          {violations?.serious}{" "}
          {t("runView.testevolveaccessibility.violations.serious")}
        </p>
        <p>
          {violations?.moderate}{" "}
          {t("runView.testevolveaccessibility.violations.moderate")}
        </p>
        <p>
          {violations?.minor}{" "}
          {t("runView.testevolveaccessibility.violations.minor")}
        </p>
      </ToolTipDiv>
    );
  };

  const mobileMargin = { top: 10, right: 10, left: -40, bottom: 40 };
  const margin = { top: 10, right: 40, left: 20, bottom: 40 };

  return (
    <GraphContainer data-test="testevolve-accessbility-run-trends-graph">
      <LineChart
        data={modifiedData.reverse()}
        margin={isNotDesktop ? mobileMargin : margin}
      >
        <Tooltip content={<CustomTooltip />} />
        <CartesianGrid vertical={false} />
        <YAxis type="number" domain={[0, "dataMax"]}>
          <Label
            angle={270}
            offset={0}
            position="left"
            style={{ textAnchor: "middle" }}
            value={t("graphs.violations")}
          />
        </YAxis>
        <XAxis dataKey="id">
          <Label
            offset={10}
            position="bottom"
            style={{ textAnchor: "middle" }}
            value={t("graphs.runId")}
          />
        </XAxis>
        <Line
          strokeWidth={4}
          type="monotone"
          dataKey="critical"
          stroke="#be1910"
        />
        <Line
          strokeWidth={4}
          type="monotone"
          dataKey="serious"
          stroke="#cb4741"
        />
        <Line
          strokeWidth={4}
          type="monotone"
          dataKey="moderate"
          stroke="#fbae39"
        />
        <Line
          strokeWidth={4}
          type="monotone"
          dataKey="minor"
          stroke="#fbe339"
        />
      </LineChart>
    </GraphContainer>
  );
};

export const TestEvolveVisualRunTrendsGraph = ({ data }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const themeObj = { light: light, dark: dark };
  const { isNotDesktop } = useApp();

  const modifiedData = data.map(obj => ({
    id: obj?.id,
    passFail: obj?.visual_summary?.checksPerformed,
    passed:
      obj?.visual_summary?.checksPerformed -
      obj?.visual_summary?.totalFailedChecks,
    failed: obj?.visual_summary?.totalFailedChecks,
  }));

  const CustomTooltip = ({ payload }) => {
    if (!payload) {
      return <ToolTipDiv />;
    }

    const total = payload.find(p => p.name === "passFail");
    const failed = payload.find(p => p.name === "failed");
    const id = payload[0]?.payload.id;

    return (
      <ToolTipDiv>
        <p key={"p-id"}>
          {id} {t("runView.testevolvevisual.id")}
        </p>
        <p key={"p-total"}>
          {total?.value} {t("runView.testevolvevisual.total.name")}
        </p>
        <p key={"p-failed"}>
          {failed?.value} {t("runView.testevolvevisual.failed.name")}
        </p>
        <p key={"p-passed"}>
          {total?.value - failed?.value}{" "}
          {t("runView.testevolvevisual.passed.name")}
        </p>
      </ToolTipDiv>
    );
  };

  const mobileMargin = { top: 10, right: 10, left: -40, bottom: 40 };
  const margin = { top: 10, right: 40, left: 20, bottom: 40 };

  return (
    <GraphContainer>
      <AreaChart
        data={modifiedData.reverse()}
        margin={isNotDesktop ? mobileMargin : margin}
      >
        <Tooltip content={<CustomTooltip />} />
        <YAxis type="number" domain={[0, "dataMax"]}>
          {isNotDesktop ? null : (
            <Label
              angle={270}
              offset={0}
              position="left"
              style={{ textAnchor: "middle" }}
              value={t("graphs.checkCount")}
            />
          )}
        </YAxis>
        <XAxis dataKey="id">
          <Label
            offset={10}
            position="bottom"
            style={{ textAnchor: "middle" }}
            value={t("graphs.runId")}
          />
        </XAxis>
        <CartesianGrid vertical={false} />
        <Area
          strokeWidth={4}
          type="monotone"
          fillOpacity={1}
          dataKey="passFail"
          stroke={themeObj[theme].colours.foreground}
          fill="url(#gradientPassedCheck)"
        />
        <Area
          strokeWidth={4}
          type="monotone"
          fillOpacity={1}
          dataKey="failed"
          stroke={themeObj[theme].colours.foreground}
          fill="url(#gradientFailedCheck)"
        />
      </AreaChart>
    </GraphContainer>
  );
};

export const TestEvolveLighthouseRunTrendsGraph = ({ data }) => {
  const { t } = useTranslation();
  const modifiedData = data.map(entry => ({
    ...entry.lighthouse_summary,
    id: entry.id,
  }));
  const { isNotDesktop } = useApp();

  const CustomTooltip = ({ payload }) => {
    if (!payload) {
      return <ToolTipDiv />;
    }

    const violations = payload[0]?.payload;
    return (
      <ToolTipDiv>
        <p>
          {t("runHistory.runId")} {payload[0]?.payload.id}
        </p>
        <p>
          {violations?.lowestPerformance}{" "}
          {t("runView.testevolvelighthouse.scores.performance")}
        </p>
        <p>
          {violations?.lowestAccessibility}{" "}
          {t("runView.testevolvelighthouse.scores.accessibility")}
        </p>
        <p>
          {violations?.lowestBestPractices}{" "}
          {t("runView.testevolvelighthouse.scores.bestPractices")}
        </p>
        <p>
          {violations?.lowestSEO} {t("runView.testevolvelighthouse.scores.seo")}
        </p>
      </ToolTipDiv>
    );
  };

  const mobileMargin = { top: 50, right: 10, left: -20, bottom: 30 };
  const margin = { top: 50, right: 30, left: 40, bottom: 30 };

  return (
    <GraphContainer
      data-test="testevolve-lighthouse-run-trends-graph"
      debounce={1}
    >
      <LineChart
        data={modifiedData.reverse()}
        margin={isNotDesktop ? mobileMargin : margin}
      >
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" height={30} wrapperStyle={{ top: 10 }} />
        <CartesianGrid vertical={false} />
        <YAxis type="number" domain={[0, 100]}>
          {isNotDesktop ? null : (
            <Label
              angle={270}
              offset={0}
              position="left"
              style={{ textAnchor: "middle" }}
              value={t("graphs.score")}
            />
          )}
        </YAxis>
        <XAxis dataKey="id">
          <Label
            offset={10}
            position="bottom"
            style={{ textAnchor: "middle" }}
            value={t("graphs.runId")}
          />
        </XAxis>
        <Line
          name={t("runView.testevolvelighthouse.scores.performance")}
          strokeWidth={4}
          type="monotone"
          dataKey="lowestPerformance"
          stroke="#FF2BB3"
        />
        <Line
          name={t("runView.testevolvelighthouse.scores.accessibility")}
          strokeWidth={4}
          type="monotone"
          dataKey="lowestAccessibility"
          stroke="#7D1EB3"
        />
        <Line
          name={t("runView.testevolvelighthouse.scores.bestPractices")}
          strokeWidth={4}
          type="monotone"
          dataKey="lowestBestPractices"
          stroke="#2B32FF"
        />
        <Line
          name={t("runView.testevolvelighthouse.scores.seo")}
          strokeWidth={4}
          type="monotone"
          dataKey="lowestSEO"
          stroke="#2BC7FF"
        />
      </LineChart>
    </GraphContainer>
  );
};
