import { useClick, useMenuState } from "@szhsin/react-menu";
import { useRef } from "react";
import { Button } from "../../components";
import { StyledPlusIcon } from "../releasesView/releasesView.styles";
import { MENU_STATE } from "../../static/constants/constants";
import { StyledMenu, StyledMenuItem } from "../nodeView/common/NodeCard.styles";
import { useModal } from "../../context/modal/modal.provider";
import { useTranslation } from "react-i18next";

export const NewRunButton = ({ getLabels }) => {
  const { t } = useTranslation();
  const menuRef = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const anchorProps = useClick(menuState.state, toggleMenu);
  const menuOpen = menuState.state === MENU_STATE.OPEN;
  const { openModal, MODALS } = useModal();

  return (
    <>
      <Button
        isOpen={menuOpen}
        buttonRef={menuRef}
        anchorProps={anchorProps}
        onClick={() => toggleMenu(!menuOpen)}
      >
        <StyledPlusIcon /> {t("btnNewRun")}
      </Button>
      <StyledMenu
        {...menuState}
        transition
        anchorRef={menuRef}
        onClose={() => {
          toggleMenu(false);
        }}
      >
        <StyledMenuItem
          onClick={() =>
            openModal(MODALS.CREATE_EXPLORATORY_RUN, { getLabels })
          }
        >
          <p>{t("newRun.exploratoryRun")}</p>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};
