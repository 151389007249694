import { useParams } from "react-router-dom";
import { TestevolveRunReport } from "./testevolve/testevolveRunReport";
import { useRunReportView } from "./testevolve/hooks/useRunReportView";
import { useEffect } from "react";
import { useJiraConfig } from "../../context/jiraConfig/jiraConfig.provider";
import { Loading } from "../../components";
import { ErrorPage } from "../../components/errorPage/errorPage";
import { useLocation } from "react-router-dom";
import { useQueryParams } from "../../context/hooks";

export const RunReportView = () => {
  const { hash } = useLocation();
  const { runType, runId } = useParams();

  const {
    run,
    error,
    getRunWithParams,
    pageData,
    runFeatures,
    resetRunFeatures,
    isFetching,
    resetPageData,
    isResolved,
    scenariosLength,
  } = useRunReportView();
  const { getConfig, isJiraConfigured, jiraLoading } = useJiraConfig();

  const { getAllParams } = useQueryParams();

  useEffect(() => {
    getConfig();

    const body = getAllParams();
    if (hash) body.feature = hash?.replace("#", "");
    if (body.status) body.statusFilter = [body.status];
    if (body.tags) body.tags = [body.tags];

    getRunWithParams(runId, body);
  }, [runId]);

  if (error) return <ErrorPage code={error.code} />;

  if (run && !jiraLoading) {
    switch (runType) {
      case "testevolve.functional":
        return (
          <TestevolveRunReport
            run={run}
            scenariosLength={scenariosLength}
            isResolved={isResolved}
            isFetching={isFetching}
            resetPageData={resetPageData}
            runFeatures={runFeatures}
            resetRunFeatures={resetRunFeatures}
            runId={runId}
            runType={runType}
            pageData={pageData}
            getRunWithParams={getRunWithParams}
            isJiraConfigured={isJiraConfigured}
            error={error}
            hash={hash}
          />
        );
      case "testevolve.audit_axe":
        return (
          <TestevolveRunReport
            run={run}
            scenariosLength={scenariosLength}
            isResolved={isResolved}
            isFetching={isFetching}
            resetPageData={resetPageData}
            runId={runId}
            pageData={pageData}
            runFeatures={runFeatures}
            resetRunFeatures={resetRunFeatures}
            getRunWithParams={getRunWithParams}
            runType={runType}
            axe={true}
            isJiraConfigured={isJiraConfigured}
            error={error}
          />
        );
      case "testevolve.audit_lighthouse":
        return (
          <TestevolveRunReport
            run={run}
            scenariosLength={scenariosLength}
            isResolved={isResolved}
            isFetching={isFetching}
            pageData={pageData}
            resetPageData={resetPageData}
            getRunWithParams={getRunWithParams}
            runId={runId}
            runFeatures={runFeatures}
            resetRunFeatures={resetRunFeatures}
            runType={runType}
            lighthouse={true}
            isJiraConfigured={isJiraConfigured}
            error={error}
          />
        );
      default:
        return null;
    }
  } else return <Loading />;
};
