import { useEffect, useState } from "react";
import { Loading } from "../../components";
import { useParams } from "react-router-dom";
import { useRunView } from "./hooks/useRunView";
import { NPMAuditRunView } from "./npmAudit/npmAuditRunView";
import { usePolling } from "../../context/hooks/poll/usePolling";
import { TestEvolveRunView } from "./testEvolve/testEvolveRunView";
import { TestEvolveVisualRunView } from "./testEvolveVisual/testEvolveVisualRunView";
import {
  POLLING_INTERVAL,
  RUN_STATUSES,
  TEST_CATEGORIES,
} from "../../static/constants/constants";
import { TestEvolveLighthouseRunView } from "./testEvolveLighthouse/testEvolveLighthouseRunView";
import { TestEvolveAccessibilityRunView } from "./testEvolveAccessibility/testEvolveAccessibilityRunView";
import { useQueryParams } from "../../context/hooks";
import { useLocation } from "react-router-dom";
import { ErrorPage } from "../../components/errorPage/errorPage";
import { useRunStatus } from "../../context/runStatus/runStatus.provider";
import { useGetRelease } from "../../context/releases/useGetRelease";
import { TestEvolveManualRunView } from "./testevolveManual/testevolveManualRunView";

const PAGE = "page";
const KIOSK_MODE = "kiosk";
const UNDEFINED_NODE_ID = "undefined";

export const RunView = ({ showRelease }) => {
  const [isPolling, setIsPolling] = useState(true);
  const { getRelease, release } = useGetRelease();

  const { runType, runId, nodeId, releaseId } = useParams();
  const {
    ref,
    run,
    getRun,
    isLatest,
    errorCode,
    runDetails,
    nodeDetails,
    isMostRecent,
    getRunDetails,
    getNodeDetails,
  } = useRunView();
  const { setRunStatusLatest } = useRunStatus();

  const location = useLocation();
  const { getParam } = useQueryParams();
  const [page, setPage] = useState(Number(getParam(PAGE)));

  const runToShow = isMostRecent && runDetails ? runDetails.latest_run : run;
  const isShared = runId === KIOSK_MODE && nodeId === KIOSK_MODE;
  const isInProgress = runToShow?.status === RUN_STATUSES.IN_PROGRESS;

  useEffect(() => {
    setPage(getParam(PAGE));
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (releaseId) getRelease(releaseId);
  }, [releaseId]);

  useEffect(() => {
    getRun(page);
    if (isLatest) setRunStatusLatest(true);
  }, [runId]);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [runId]);

  useEffect(() => {
    if (nodeId !== UNDEFINED_NODE_ID) getNodeDetails();
  }, [nodeId, runId]);

  usePolling(() => {
    if ((isLatest || isInProgress) && isPolling) getRunDetails(page);
  }, POLLING_INTERVAL);

  usePolling(() => {
    if (
      runType === TEST_CATEGORIES.TEST_EVOLVE_EXPLORATORY &&
      !isLatest &&
      isPolling
    )
      getRun();
  }, POLLING_INTERVAL);

  const renderRunView = () => {
    switch (runType) {
      case TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL:
        return (
          <TestEvolveRunView
            scrollRef={ref}
            run={runToShow}
            nodeDetails={nodeDetails}
            runDetails={runDetails}
            isShared={isShared}
            isLatest={isLatest}
            isMostRecent={isMostRecent}
            getRunDetails={getRunDetails}
            setIsPolling={setIsPolling}
            release={showRelease ? release : null}
          />
        );
      case TEST_CATEGORIES.NPM_AUDIT:
        return (
          <NPMAuditRunView
            scrollRef={ref}
            run={runToShow}
            nodeDetails={nodeDetails}
            runDetails={runDetails}
            isShared={isShared}
            isLatest={isLatest}
            getRunDetails={getRunDetails}
            setIsPolling={setIsPolling}
            release={showRelease ? release : null}
          />
        );
      case TEST_CATEGORIES.TEST_EVOLVE_AUDIT_AXE:
        return (
          <TestEvolveAccessibilityRunView
            scrollRef={ref}
            run={runToShow}
            nodeDetails={nodeDetails}
            runDetails={runDetails}
            isShared={isShared}
            isLatest={isLatest}
            getRunDetails={getRunDetails}
            setIsPolling={setIsPolling}
            release={showRelease ? release : null}
          />
        );
      case TEST_CATEGORIES.TEST_EVOLVE_VISUAL:
        return (
          <TestEvolveVisualRunView
            scrollRef={ref}
            run={runToShow}
            nodeDetails={nodeDetails}
            runDetails={runDetails}
            isShared={isShared}
            isLatest={isLatest}
            getRunDetails={getRunDetails}
            setIsPolling={setIsPolling}
            release={showRelease ? release : null}
          />
        );
      case TEST_CATEGORIES.TEST_EVOLVE_AUDIT_LIGHTHOUSE:
        return (
          <TestEvolveLighthouseRunView
            scrollRef={ref}
            run={runToShow}
            nodeDetails={nodeDetails}
            runDetails={runDetails}
            isShared={isShared}
            isLatest={isLatest}
            getRunDetails={getRunDetails}
            setIsPolling={setIsPolling}
            release={showRelease ? release?.name : null}
          />
        );
      case TEST_CATEGORIES.TEST_EVOLVE_EXPLORATORY:
        return (
          <TestEvolveManualRunView
            scrollRef={ref}
            run={runToShow}
            nodeDetails={nodeDetails}
            runDetails={runDetails}
            isShared={isShared}
            isLatest={isLatest}
            getRun={getRun}
            getRunDetails={getRunDetails}
            setIsPolling={setIsPolling}
            release={showRelease ? release?.name : null}
          />
        );
      default:
        return null;
    }
  };

  return errorCode ? (
    <ErrorPage code={errorCode} />
  ) : (isLatest && runDetails?.latest_run) || (!isLatest && run) ? (
    renderRunView()
  ) : (
    <Loading />
  );
};
