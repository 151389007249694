import { RichTextEditor } from "../../../../components/richTextEditor/richTextEditor";
import {
  Attachment,
  AttachmentsContainer,
  EntryHeader,
  EntryRow,
  EntryTopBar,
} from "../testevolveManualRunView.styles";
import { EntryType } from "./entryType";
import { ReactComponent as ImageIcon } from "../../../../static/assets/icon-image.svg";
import { ReactComponent as VideoIcon } from "../../../../static/assets/icon-video.svg";
import { ReactComponent as FileIcon } from "../../../../static/assets/icon-file.svg";
import { useModal } from "../../../../context/modal/modal.provider";
import { timeAgo } from "../../../../utils/dates/dateUtils";
import { FILE_TYPES } from "../../../../static/constants/constants";

export const Entry = ({
  entry,
  isClosed,
  setHovered,
  isOwnSession,
  setEditExistingEntry,
}) => {
  const { openModal, MODALS } = useModal();

  return (
    <EntryRow onClick={() => setEditExistingEntry(entry.id)}>
      <EntryTopBar>
        <EntryType type={entry.type.toUpperCase()} />
        <EntryHeader>{timeAgo(entry.updated_time)}</EntryHeader>
      </EntryTopBar>
      <RichTextEditor
        readOnly={true}
        initial={entry.content}
        identifier={`entry-${entry.id}`}
      />
      <AttachmentsContainer>
        {entry.attachments?.map(att => (
          <Attachment
            key={att}
            onMouseOver={() => setHovered(att)}
            onMouseLeave={() => setHovered(null)}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              openModal(MODALS.ENTRY_ATTACHMENTS, {
                attachments: entry.attachments,
                active: att,
                isClosed,
                isOwnSession,
              });
            }}
          >
            {att.content_type?.split("/")[0] === FILE_TYPES.IMAGE ? (
              <ImageIcon />
            ) : att.content_type?.split("/")[0] === FILE_TYPES.VIDEO ? (
              <VideoIcon />
            ) : (
              <FileIcon />
            )}
            <p>{att.file_name}</p>
          </Attachment>
        ))}
      </AttachmentsContainer>
    </EntryRow>
  );
};
