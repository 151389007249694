import { useEffect, useRef, useState, memo } from "react";
import { timeAgo, mediumDateTime } from "../../../../utils/dates/dateUtils";
import {
  ButtonContainer,
  DetailKey,
  DetailValue,
  DetailValueIcon,
  EditRiskForm,
  NumberInput,
  ReferenceContainer,
  RiskInputContainer,
  RiskInputsContainer,
  RunDetail,
  RunDetailsContainer,
} from "../testevolveManualRunView.styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as IconCopy } from "../../../../static/assets/icon-copy.svg";
import { StyledCopyTrueIcon } from "../../components/metadataTab/metadataTab.styles";
import { RunStatusContextMenu } from "../../runDetails/runStatusContextMenu";
import { usePermissions } from "../../../../context/hooks";
import { StatusStickerContainer } from "../../../nodeView/nodeCardContainer/nodeCardTestEvolveManual/nodeCardTestEvolveManual.styles";
import {
  CLICK,
  EXPLORATORY_PROPS,
} from "../../../../static/constants/constants";
import { Button } from "../../../../components";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";

export const ManualRunDetailsUnMemo = ({ run, editField, getRun }) => {
  const barRef = useRef(null);
  const { t } = useTranslation();
  const { isAdmin } = usePermissions();
  const [copied, setCopied] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [barHeight, setBarHeight] = useState(null);
  const [editValue, setEditValue] = useState(false);

  useEffect(() => {
    if (!editMode && barRef?.current)
      setBarHeight(barRef.current.getBoundingClientRect().height);
  }, [editMode, barRef]);

  const handleFieldChange = (key, e) => {
    if (e.key === "Enter") setEditMode(false);
    setEditValue(v => ({ ...v, [key.toLowerCase()]: e.target.value }));
  };

  useEffect(() => {
    setEditValue(false);
  }, [editMode]);

  useEffect(() => {
    setEditMode(false);
  }, [run]);

  useEffect(() => {
    if (copied) setTimeout(() => setCopied(false), 3000);
  }, [copied]);

  const handleClick = e => {
    if (!e.target.id.includes("target")) setEditMode(false);
  };

  useEffect(() => {
    document.addEventListener(CLICK, handleClick, true);

    return () => {
      document.removeEventListener(CLICK, handleClick);
    };
  }, []);

  const handleSaveButton = () => {
    editField(editValue);
    setEditMode(false);
  };

  return (
    <RunDetailsContainer ref={barRef} h={editMode ? barHeight : null}>
      <StatusStickerContainer>
        <RunStatusContextMenu
          admin={isAdmin()}
          run={run}
          editField={editField}
          getRun={getRun}
          disabled={run.releases?.length}
        />
      </StatusStickerContainer>
      <RunDetail
        reference
        editHeight
        editMode={editMode === EXPLORATORY_PROPS.REFERENCE}
      >
        <DetailKey>
          {t("runView.testevolveexploratory.runDetails.reference")}
        </DetailKey>
        {editMode === EXPLORATORY_PROPS.REFERENCE ? (
          <>
            <textarea
              id="text-area-target"
              type="text"
              autoFocus
              onFocus={e => e.target.select()}
              onKeyUp={e => handleFieldChange(EXPLORATORY_PROPS.REFERENCE, e)}
              defaultValue={run.reference}
            />
            <ButtonContainer>
              <Button secondary onClick={null}>
                {t("btnCancel")}
              </Button>
              <Button buttonId="save-target" onClick={handleSaveButton}>
                {t("btnSave")}
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <DetailValueIcon>
            <ReferenceContainer
              onClick={() => setEditMode(EXPLORATORY_PROPS.REFERENCE)}
            >
              <DetailValue reference>{run.reference}</DetailValue>
            </ReferenceContainer>
            <CopyToClipboard
              text={run.reference}
              onCopy={() => setCopied(true)}
            >
              {copied ? <StyledCopyTrueIcon accent /> : <IconCopy />}
            </CopyToClipboard>
          </DetailValueIcon>
        )}
      </RunDetail>
      <RunDetail
        desc
        onClick={() => setEditMode(EXPLORATORY_PROPS.DESCRIPTION)}
        editMode={editMode === EXPLORATORY_PROPS.DESCRIPTION}
        editHeight
      >
        <DetailKey>
          {t("runView.testevolveexploratory.runDetails.description")}
        </DetailKey>
        {editMode === EXPLORATORY_PROPS.DESCRIPTION ? (
          <>
            <textarea
              id="text-area-target"
              type="text"
              autoFocus
              onFocus={e => e.target.select()}
              onKeyUp={e => handleFieldChange(EXPLORATORY_PROPS.DESCRIPTION, e)}
              defaultValue={run.description}
            />
            <ButtonContainer>
              <Button secondary onClick={null}>
                {t("btnCancel")}
              </Button>
              <Button buttonId="save-target" onClick={handleSaveButton}>
                {t("btnSave")}
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <DetailValue desc>{run.description}</DetailValue>
        )}
      </RunDetail>
      <RunDetail>
        <DetailKey>
          {t("runView.testevolveexploratory.runDetails.started")}
        </DetailKey>
        <DetailValue>{mediumDateTime(run.start_time)}</DetailValue>
      </RunDetail>
      <RunDetail>
        <DetailKey>
          {t("runView.testevolveexploratory.runDetails.lastUpdated")}
        </DetailKey>
        <DetailValue>{timeAgo(run.updated_time)}</DetailValue>
      </RunDetail>
      {editMode === EXPLORATORY_PROPS.RISK ? (
        <EditRiskForm>
          <RiskInputsContainer>
            <RiskInputContainer>
              <DetailKey>
                {t("runView.testevolveexploratory.runDetails.probability")}
              </DetailKey>
              <NumberInput
                probact={editValue?.probability || run.probability}
                id="probability-target"
                type="number"
                min="1"
                max="3"
                autoFocus
                onFocus={e => e.target.select()}
                onKeyUp={e =>
                  handleFieldChange(EXPLORATORY_PROPS.PROBABILITY, e)
                }
                onChange={e =>
                  handleFieldChange(EXPLORATORY_PROPS.PROBABILITY, e)
                }
                defaultValue={Number(run.probability)}
              />
            </RiskInputContainer>
            <RiskInputContainer>
              <DetailKey>
                {t("runView.testevolveexploratory.runDetails.impact")}
              </DetailKey>
              <NumberInput
                probact={editValue?.impact || run.impact}
                id="impact-target"
                type="number"
                min="1"
                max="3"
                autoFocus
                onFocus={e => e.target.select()}
                onKeyUp={e => handleFieldChange(EXPLORATORY_PROPS.IMPACT, e)}
                onChange={e => handleFieldChange(EXPLORATORY_PROPS.IMPACT, e)}
                defaultValue={Number(run.impact)}
              />
            </RiskInputContainer>
          </RiskInputsContainer>
          <ButtonContainer>
            <Button secondary onClick={null}>
              {t("btnCancel")}
            </Button>
            <Button buttonId="save-target" onClick={handleSaveButton}>
              {t("btnSave")}
            </Button>
          </ButtonContainer>
        </EditRiskForm>
      ) : (
        <>
          <RunDetail
            probact={
              editMode === EXPLORATORY_PROPS.RISK && editValue
                ? editValue
                : run.probability
            }
            onClick={() => setEditMode(EXPLORATORY_PROPS.RISK)}
            editMode={editMode === EXPLORATORY_PROPS.PROBABILITY}
          >
            <DetailKey>
              {t("runView.testevolveexploratory.runDetails.probability")}
            </DetailKey>
            <DetailValue>{run.probability || "N/A"}</DetailValue>
          </RunDetail>
          <RunDetail
            probact={
              editMode === EXPLORATORY_PROPS.IMPACT && editValue
                ? editValue
                : run.impact
            }
            onClick={() => setEditMode(EXPLORATORY_PROPS.RISK)}
            editMode={editMode === EXPLORATORY_PROPS.RISK.IMPACT}
          >
            <DetailKey>
              {t("runView.testevolveexploratory.runDetails.impact")}
            </DetailKey>
            <DetailValue>{run.impact || "N/A"}</DetailValue>
          </RunDetail>
        </>
      )}
      <RunDetail
        risk={
          editValue.probability * editValue.impact ||
          editValue.probability * run.impact ||
          editValue.impact * run.probability ||
          run.probability * run.impact ||
          false
        }
      >
        <DetailKey>
          {t("runView.testevolveexploratory.runDetails.risk")}
        </DetailKey>
        <DetailValue>
          {editValue.probability * editValue.impact ||
            editValue.probability * run.impact ||
            editValue.impact * run.probability ||
            run.probability * run.impact ||
            t("runView.testevolveexploratory.runDetails.n/a")}
        </DetailValue>
      </RunDetail>
    </RunDetailsContainer>
  );
};

export const ManualRunDetails = memo(
  ManualRunDetailsUnMemo,
  (prevProps, currentProps) => isEqual(prevProps.run, currentProps.run)
);
