import { useEffect } from "react";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../../context/hooks";

export const useCreateManualRun = () => {
  const { post, status, body } = useFetch();
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (status.isResolved && body)
      createNotification(
        types.SUCCESS,
        "runView.testevolveexploratory.createRunSuccess"
      );
    if (status.isRejected)
      createNotification(
        types.ERROR,
        "runView.testevolveexploratory.createRunError"
      );
  }, [status.isRejected, status.isResolved, body]);

  const createManualRun = e => post(ENDPOINTS.EXPLORATORY_RUN, e);

  return {
    createManualRun,
    createManualRunSucess: status.isResolved,
  };
};
