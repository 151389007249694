import { useTranslation } from "react-i18next";
import {
  DurationGraph,
  TestEvolveAccessibilityRunTrendsGraph,
} from "../../../../components/graph/graph";
import { Container, GraphContainer, TrendsTitle } from "./runTrends.styles";
import { RunTrendsHeaderContainer } from "../../testEvolve/runTrends/runTrends.styles";
import { ComparisonSelect } from "../../testEvolve/rightWidget/runInsights/runInsights.styles";
import { useState } from "react";
import { DURATION, VIOLATIONS } from "../../../../static/constants/constants";
import { useRunDurations } from "../../hooks/useDurations";

export const RunTrends = ({ runDetails }) => {
  const { t } = useTranslation();
  const { durationsMap } = useRunDurations();
  const [toggleValue, setToggleValue] = useState(VIOLATIONS);

  return (
    <Container>
      <RunTrendsHeaderContainer>
        <TrendsTitle>
          {t("runView.testevolveaccessibility.runTrendsTitle")}
        </TrendsTitle>
        <ComparisonSelect
          value={toggleValue}
          onChange={e => setToggleValue(e.target.value)}
          title="select-testevolve-accessibility-title"
          aria-label="Showing Graph:"
        >
          <option value={VIOLATIONS}>
            {t("runView.runTrends.violations")}
          </option>
          <option value={DURATION}>{t("runView.runTrends.duration")}</option>
        </ComparisonSelect>
      </RunTrendsHeaderContainer>

      {toggleValue === VIOLATIONS ? (
        runDetails?.run_history?.length ? (
          <GraphContainer data-test="te-accessibility-runtrends-graph">
            <TestEvolveAccessibilityRunTrendsGraph
              data={runDetails?.run_history}
            />
          </GraphContainer>
        ) : null
      ) : (
        <DurationGraph data={durationsMap(runDetails?.run_history)} />
      )}
    </Container>
  );
};
