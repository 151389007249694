import { useLatestRun } from "../hooks/useLatestRun";
import { BarChart, XAxis, Bar, Cell, CartesianGrid, YAxis } from "recharts";
import { useTranslation } from "react-i18next";
import {
  Container,
  GraphContainer,
  RunStatusContainer,
  RunStatusSticker,
  RunStatusStickerBox,
  RunStatusTitle,
  RunStatusValue,
  StickersContainer,
  Section,
  StandardsContainer,
  Heading,
  ValContainer,
  StandardContainer,
} from "./violationsSection.styles";

export const ViolationsSection = ({ run }) => {
  const {
    checksPerformed,
    totalViolations,
    accessibilityStandards,
    violationsChartData,
  } = useLatestRun(run);
  const { t } = useTranslation();

  return (
    <Container>
      <Section data-test="violations-chart">
        <GraphContainer data-test="violations-chart">
          <BarChart
            barSize={12}
            data-test="violations-chart"
            data={violationsChartData}
          >
            <CartesianGrid vertical={false} />
            <YAxis type="number" domain={[0, "dataMax"]} />
            <XAxis
              dataKey="name"
              scale="point"
              padding={{ left: 30, right: 30 }}
            />
            <Bar
              dataKey="count"
              isAnimationActive={false}
              radius={[10, 10, 0, 0]}
            >
              {violationsChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={`url(#gradient${entry.name}`}
                  strokeWidth={index === 2 ? 4 : 1}
                  title={`${entry.name}-cell`}
                />
              ))}
            </Bar>
          </BarChart>
        </GraphContainer>
      </Section>
      <Section>
        <StickersContainer>
          <RunStatusStickerBox>
            <RunStatusSticker>
              <RunStatusContainer data-test="te-accessibility-checks-sticker">
                <RunStatusTitle>
                  {t("runView.testevolveaccessibility.numberOfChecksTitle")}
                </RunStatusTitle>
                <RunStatusValue>{checksPerformed}</RunStatusValue>
              </RunStatusContainer>
            </RunStatusSticker>
          </RunStatusStickerBox>
          <RunStatusStickerBox>
            <RunStatusSticker>
              <RunStatusContainer data-test="te-accessibility-violations-sticker">
                <RunStatusTitle>
                  {t("runView.testevolveaccessibility.totalViolationsTitle")}
                </RunStatusTitle>
                <RunStatusValue>{totalViolations}</RunStatusValue>
              </RunStatusContainer>
            </RunStatusSticker>
          </RunStatusStickerBox>
        </StickersContainer>
        <StandardsContainer>
          {accessibilityStandards
            ? Object.keys(accessibilityStandards).map(key => (
                <StandardContainer
                  data-test={`${key}-sticker`}
                  key={`${key}-sticker`}
                >
                  <Heading>{key}</Heading>
                  <ValContainer>
                    <p>{accessibilityStandards[key]}</p>
                  </ValContainer>
                </StandardContainer>
              ))
            : null}
        </StandardsContainer>
      </Section>
    </Container>
  );
};
