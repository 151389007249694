import { useState } from "react";
import { RUN_CATEGORIES, TABS } from "../../../static/constants/constants";
import { useJiraConfig } from "../../../context/jiraConfig/jiraConfig.provider";

const functionalTabsWithoutInsights = [TABS.FEATURES, TABS.SUMMARY];
const functionalTabsWithInsights = [TABS.FEATURES, TABS.INSIGHTS, TABS.SUMMARY];
const accessibilityTabs = [TABS.SCENARIOS];
const auditTabs = [TABS.SCENARIOS];
const visualTabs = [TABS.SCENARIOS];

export const useCardTabs = run => {
  const { isJiraConfigured } = useJiraConfig();

  const getTabs = showInsights => {
    let tabs = [];
    switch (run.category) {
      case RUN_CATEGORIES.functional:
        tabs = showInsights
          ? functionalTabsWithInsights
          : functionalTabsWithoutInsights;
        break;
      case RUN_CATEGORIES.accessibility:
        tabs = accessibilityTabs;
        break;
      case RUN_CATEGORIES.audit:
        tabs = auditTabs;
        break;
      case RUN_CATEGORIES.visual:
        tabs = visualTabs;
        break;
      default:
        tabs = functionalTabsWithoutInsights;
        break;
    }

    tabs = [...tabs, TABS.METADATA];
    if (isJiraConfigured) tabs = [...tabs, TABS.BUGS];
    return tabs;
  };

  const activeTabState = useState(getTabs()[0]);

  return {
    activeTabState,
    getTabs,
  };
};
