import { isEqual } from "lodash";
import { NewEntryForm } from "./newEntryForm";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components";
import { memo, useEffect, useState } from "react";
import { useEntries } from "../hooks/entry/useEntries";
import { convertDateToTs } from "../../../../utils/dates/dateUtils";
import image from "../../../../static/assets/no_sessions.png";
import {
  CLICK,
  EXPLORATORY_PROPS,
  RUN_STATUSES,
} from "../../../../static/constants/constants";
import { StyledPlusIcon } from "../../../releasesView/releasesView.styles";
import {
  EmptyContainer,
  SessionsHeader,
  EntryContainer,
  SectionContainer,
  EntryDetails,
  RunDetail,
  DetailKey,
  ButtonContainer,
  DetailValue,
} from "../testevolveManualRunView.styles";
import { usePermissions } from "../../../../context/hooks";
import { useCloseSession } from "../hooks/session/useCloseSession";
import { useOpenSession } from "../hooks/session/useOpenSession";
import { useDeleteSession } from "../hooks/session/useDeleteSession";
import { Spacer } from "../../../runReportView/components/bug.styles";
import { useEditSessionSummary } from "../hooks/session/useEditSessionSummary";
import { useModal } from "../../../../context/modal/modal.provider";
import { useUser } from "../../../../context/user/user.provider";
import { sortObject } from "../../../../utils/sort/sortObject";
import { Entry } from "./entry";

export const CurrentSessionUnMemo = ({
  session,
  getRun,
  runClosed,
  setIsPolling,
  getRunDetails,
}) => {
  const { t } = useTranslation();
  const { username } = useUser();
  const { isAdmin } = usePermissions();
  const { openModal, MODALS } = useModal();

  const [hovered, setHovered] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editExistingEntry, setEditExistingEntry] = useState(false);

  const close = () => {
    setEditExistingEntry(false);
    setIsEditMode(false);
  };

  const { editSummary } = useEditSessionSummary(session?.id, getRun);
  const { openSession } = useOpenSession(session?.id, getRun);
  const { closeSession } = useCloseSession(session?.id, getRun);
  const { deleteSession } = useDeleteSession(
    session?.id,
    getRun,
    getRunDetails
  );
  const { editEntry, deleteEntry, createEntry, loading } = useEntries(
    session?.id,
    getRun,
    close
  );

  const isOwnSession = session?.owner === username;
  const isClosed = session?.status?.toLowerCase() === RUN_STATUSES.CLOSED;

  useEffect(() => {
    setEditValue(false);
  }, [editMode]);

  useEffect(() => {
    if (isEditMode || editExistingEntry) setIsPolling(false);
    else setIsPolling(true);
  }, [isEditMode, editExistingEntry]);

  useEffect(() => {
    setEditMode(false);
  }, [session]);

  const handleFieldChange = (key, e) => {
    setEditValue(v => ({ ...v, [key.toLowerCase()]: e.target.value }));
  };

  const handleClick = e => {
    if (!e.target.id.includes("target")) setEditMode(false);
  };

  useEffect(() => {
    document.addEventListener(CLICK, handleClick, true);

    return () => {
      document.removeEventListener(CLICK, handleClick);
    };
  }, []);

  useEffect(() => {
    setIsEditMode(false);
  }, [session]);

  const handleSaveButton = () => {
    editSummary(editValue);
    setEditMode(false);
  };

  const handleButtonClick = () => {
    if (isClosed) openSession();
    else
      openModal(MODALS.EDIT_SESSION_SUMMARY, {
        session,
        editSummary,
        closeSession,
      });
  };

  if (!session) return null;

  return (
    <SectionContainer left>
      <SessionsHeader closed={isClosed}>
        <h1>
          {t("runView.testevolveexploratory.currentSession.ownersSession", {
            owner: session.owner.split("@")[0],
          })}
        </h1>
        <Spacer />
        {isAdmin() || isOwnSession ? (
          <Button
            danger
            onClick={() =>
              openModal(MODALS.DELETE_SESSION, {
                isMine: isOwnSession,
                deleteSession,
                session,
              })
            }
          >
            {t("btnDelete")}
          </Button>
        ) : null}
        {isOwnSession && !runClosed ? (
          <Button open onClick={handleButtonClick}>
            {t(
              isClosed
                ? "runView.testevolveexploratory.currentSession.reopenSession"
                : "runView.testevolveexploratory.currentSession.endSession"
            )}
          </Button>
        ) : null}
        {session?.id && !isClosed && isOwnSession ? (
          <Button onClick={() => setIsEditMode(true)}>
            <StyledPlusIcon />{" "}
            {t("runView.testevolveexploratory.currentSession.newEntry")}
          </Button>
        ) : null}
      </SessionsHeader>
      <EntryDetails closed={isClosed}>
        <RunDetail
          desc
          onClick={() =>
            !isOwnSession || isClosed
              ? null
              : setEditMode(EXPLORATORY_PROPS.SUMMARY)
          }
          editMode={
            editMode && isOwnSession && !isClosed === EXPLORATORY_PROPS.SUMMARY
          }
          editHeight
        >
          <DetailKey>
            {t("runView.testevolveexploratory.currentSession.summary")}
          </DetailKey>
          {editMode === EXPLORATORY_PROPS.SUMMARY &&
          !isClosed &&
          isOwnSession ? (
            <>
              <textarea
                id="text-area-target"
                type="text"
                autoFocus
                onFocus={e => e.target.select()}
                onKeyUp={e => handleFieldChange(EXPLORATORY_PROPS.SUMMARY, e)}
                defaultValue={session?.summary}
              />
              <ButtonContainer>
                <Button secondary onClick={null}>
                  {t("btnCancel")}
                </Button>
                <Button buttonId="save-target" onClick={handleSaveButton}>
                  {t("btnSave")}
                </Button>
              </ButtonContainer>
            </>
          ) : (
            <DetailValue desc summary>
              {session?.summary}
            </DetailValue>
          )}
        </RunDetail>
      </EntryDetails>
      <EntryContainer closed={isClosed}>
        {isEditMode && !isClosed && isOwnSession ? (
          <NewEntryForm
            sessionId={session.id}
            addEntry={createEntry}
            close={close}
            isAdmin={isAdmin()}
            isOwnSession={isOwnSession}
            loading={loading}
          />
        ) : null}
        {session?.entries && session?.entries?.length ? (
          session?.entries
            ?.sort(
              (a, b) =>
                convertDateToTs(b.start_time) - convertDateToTs(a.start_time)
            )
            .map(entry =>
              editExistingEntry === entry.id &&
              !isClosed &&
              (isOwnSession || isAdmin()) ? (
                <NewEntryForm
                  sessionId={session.id}
                  entry={entry}
                  key={`edit-entry-${entry.id}`}
                  editEntry={editEntry}
                  close={close}
                  deleteEntry={deleteEntry}
                  isAdmin={isAdmin()}
                  isOwnSession={isOwnSession}
                  loading={loading}
                />
              ) : (
                <Entry
                  key={`entry-${entry.id}`}
                  entry={entry}
                  hovered={hovered}
                  setHovered={setHovered}
                  setEditExistingEntry={setEditExistingEntry}
                  isOwnSession={isOwnSession}
                  isClosed={isClosed}
                />
              )
            )
        ) : (
          <EmptyContainer>
            <img
              src={image}
              alt={t(
                "runView.testevolveexploratory.currentSession.noEntriesAlt"
              )}
            />
            <p>{t("runView.testevolveexploratory.currentSession.noEntries")}</p>
          </EmptyContainer>
        )}
      </EntryContainer>
    </SectionContainer>
  );
};

const sortArray = arr =>
  arr.map(obj => sortObject(obj)).sort((a, b) => a?.id - b?.id) || [];

export const CurrentSession = memo(CurrentSessionUnMemo, (p, c) => {
  if (!c.session || !p.session || !c.session?.entries || !p.session?.entries)
    return null;

  const { entries: currentEntries, ...currentProps } = c.session;
  const { entries: previousEntries, ...previousProps } = p.session;

  return (
    isEqual(sortObject(previousProps), sortObject(currentProps)) &&
    isEqual(sortArray(currentEntries), sortArray(previousEntries)) &&
    isEqual(c.runClosed, p.runClosed)
  );
});
