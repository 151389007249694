import React from "react";
import {
  Mobile,
  Tablet,
  Desktop,
  Local,
  SauceLabs,
  Linux,
  Android,
  Apple,
  IOS,
  Opera,
  Edge,
  BrowserStack,
  Perfecto,
  Windows,
  Safari,
  Firefox,
  InternetExplorer,
  Chrome,
  Browser,
  LocalDevice,
  Spark,
  Node,
  Ruby,
  Python,
} from "../testTypes/testTypeIcons";

const TABLET_DEVICE_KEYWORDS = ["ipad", "galaxy tab", "android_tablet"];
const DESKTOP_KEYWORDS = [
  "desktop",
  "pc",
  "desktop_app",
  "testevolve.desktop_app",
  "testevolve.desktopapp",
];

export const useTestTypeIcon = () => {
  const determineTablet = property => {
    return TABLET_DEVICE_KEYWORDS.includes(property);
  };

  const determineDesktop = property => {
    return DESKTOP_KEYWORDS.includes(property);
  };

  const returnIcon = (runPropertyValue, runProperty) => {
    const sanitizedPropertyValue = runPropertyValue
      ? runPropertyValue.toString().toLowerCase()
      : "";

    if (runProperty === "spark_version") return <Spark />;
    if (runProperty === "node_version") return <Node />;
    if (runProperty === "ruby_version") return <Ruby />;
    if (runProperty === "python_version") return <Python />;

    const isTablet = determineTablet(sanitizedPropertyValue);
    const isDesktop = determineDesktop(sanitizedPropertyValue);

    if (isTablet) return <Tablet />;
    if (isDesktop) return <Desktop />;

    if (runProperty === "device" && !isTablet && !isDesktop) return <Mobile />;

    if (
      runProperty === "environment" ||
      runProperty === "tags" ||
      runProperty === "label" ||
      runProperty === "path"
    ) {
      return null;
    }

    const IconMap = [
      {
        key: "chrome",
        values: ["chrome"],
        icon: <Chrome />,
      },
      {
        key: "safari",
        values: ["safari"],
        icon: <Safari />,
      },
      {
        key: "firefox",
        values: ["firefox", "mozilla", "moz"],
        icon: <Firefox />,
      },
      {
        key: "edge",
        values: ["edge"],
        icon: <Edge />,
      },
      {
        key: "internet_explorer",
        values: [
          "ie",
          "internet explorer",
          "internetexplorer",
          "internetExplorer",
          "internet_explorer",
        ],
        icon: <InternetExplorer />,
      },
      {
        key: "opera",
        values: ["opera"],
        icon: <Opera />,
      },
      {
        key: "windows",
        values: ["windows", "win"],
        icon: <Windows />,
      },
      {
        key: "mac",
        values: [
          "osx",
          "os_x",
          "os x",
          "mac",
          "apple",
          "macos",
          "mac os",
          "mac os x",
          "macos x",
          "macosx",
          "mac_os_x",
          "macOS",
        ],
        icon: <Apple />,
      },
      {
        key: "ios",
        values: ["ios"],
        icon: <IOS />,
      },
      {
        key: "android",
        values: ["android"],
        icon: <Android />,
      },
      {
        key: "linux",
        values: ["Linux", "linux", "Fedora", "fedora", "Ubuntu", "ubuntu"],
        icon: <Linux />,
      },
      {
        key: "browserstack",
        values: ["browserstack", "browser stack"],
        icon: <BrowserStack />,
      },
      {
        key: "perfecto",
        values: ["perfecto"],
        icon: <Perfecto />,
      },
      {
        key: "sauceLabs",
        values: ["sauceLabs", "sauce labs"],
        icon: <SauceLabs />,
      },
      {
        key: "local",
        values: ["local", "localhost"],
        icon: <Local />,
      },
      {
        key: "localdevice",
        values: ["local_device"],
        icon: <LocalDevice />,
      },
      {
        key: "browser",
        values: ["browser", "testevolve.browser"],
        icon: <Browser />,
      },
    ];

    const findPropertyValueInIconMap = () => {
      const foundIcon = IconMap.find(item => {
        const match =
          item.values &&
          item.values.find(
            val =>
              sanitizedPropertyValue &&
              sanitizedPropertyValue.length > 0 &&
              Object.is(sanitizedPropertyValue, val)
          );
        if (match) {
          return match;
        }
        return false;
      });

      return foundIcon ? foundIcon.icon : "";
    };

    return findPropertyValueInIconMap();
  };

  return {
    returnIcon,
  };
};
