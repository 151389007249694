import React from "react";
import {
  Container,
  SectionHeader,
  StyledScenariosTable,
} from "./scenarios.styles";
import { useTranslation } from "react-i18next";
import { useLatestRun } from "../../latestRunCard/hooks/useLatestRun";
import { Scenario } from "./scenario/scenario";
import { useParams } from "react-router-dom";
import { NoScenariosP } from "../../../testEvolveVisual/rightWidget/scenarios/scenarios.styles";

export const Scenarios = ({ run, isShared }) => {
  const { nodeId } = useParams();
  const { t } = useTranslation();
  const { failedScenarios, passedScenarios, errorScenarios, skippedScenarios } =
    useLatestRun(run);

  const StatusSection = ({ status, first }) => {
    return (
      <SectionHeader color={status} first={first}>
        <h2>{t(`runView.testevolveaccessibility.${status}.name`)}</h2>
        <p>{t(`runView.testevolveaccessibility.${status}.description`)}</p>
      </SectionHeader>
    );
  };

  const TableHeaderRow = ({ hideSeverities }) => {
    return (
      <thead>
        <tr>
          <th>{t("runView.testevolveaccessibility.scenarioNameColumn")}</th>
          <th />
          <th>
            {!hideSeverities &&
              t("runView.testevolveaccessibility.violations.critical")}
          </th>
          <th>
            {!hideSeverities &&
              t("runView.testevolveaccessibility.violations.serious")}
          </th>
          <th>
            {!hideSeverities &&
              t("runView.testevolveaccessibility.violations.moderate")}
          </th>
          <th>
            {!hideSeverities &&
              t("runView.testevolveaccessibility.violations.minor")}
          </th>
        </tr>
      </thead>
    );
  };

  const scenariosList = () => {
    return (
      <Container>
        <StatusSection status="failed" first={true} />
        {failedScenarios && failedScenarios.length ? (
          <StyledScenariosTable>
            <TableHeaderRow hideSeverities={!failedScenarios.length} />
            <tbody>
              {failedScenarios.map((item, index) => {
                return (
                  <Scenario
                    key={index}
                    name={item.name}
                    checksPerformed={item.axe.checksPerformed}
                    critical={item.axe.critical}
                    serious={item.axe.serious}
                    moderate={item.axe.moderate}
                    low={item.axe.minor}
                    run={run}
                    nodeId={nodeId}
                    isShared={isShared}
                  />
                );
              })}
            </tbody>
          </StyledScenariosTable>
        ) : (
          <NoScenariosP>{t("noScenarios")}</NoScenariosP>
        )}

        <StatusSection status="error" />
        {errorScenarios && errorScenarios.length ? (
          <StyledScenariosTable>
            <TableHeaderRow hideSeverities />
            <tbody>
              {errorScenarios.map((item, index) => {
                return (
                  <Scenario
                    key={index}
                    name={item.name}
                    checksPerformed={item.axe.checksPerformed}
                    critical={item.axe.critical}
                    serious={item.axe.serious}
                    moderate={item.axe.moderate}
                    low={item.axe.minor}
                    run={run}
                    nodeId={nodeId}
                    isShared={isShared}
                  />
                );
              })}
            </tbody>
          </StyledScenariosTable>
        ) : (
          <NoScenariosP>{t("noScenarios")}</NoScenariosP>
        )}
        <StatusSection status="passed" />
        {passedScenarios && passedScenarios.length ? (
          <StyledScenariosTable>
            <TableHeaderRow hideSeverities />
            <tbody>
              {passedScenarios.map((item, index) => {
                return (
                  <Scenario
                    key={index}
                    name={item.name}
                    checksPerformed={item.axe.checksPerformed}
                    run={run}
                    nodeId={nodeId}
                    isShared={isShared}
                  />
                );
              })}
            </tbody>
          </StyledScenariosTable>
        ) : (
          <NoScenariosP>{t("noScenarios")}</NoScenariosP>
        )}
        <StatusSection status="skipped" />
        {skippedScenarios && skippedScenarios.length ? (
          <StyledScenariosTable>
            <TableHeaderRow hideSeverities />
            <tbody>
              {skippedScenarios.map((item, index) => (
                <Scenario
                  key={index}
                  name={item.name}
                  run={run}
                  nodeId={nodeId}
                  isShared={isShared}
                />
              ))}
            </tbody>
          </StyledScenariosTable>
        ) : (
          <NoScenariosP>{t("noScenarios")}</NoScenariosP>
        )}
      </Container>
    );
  };

  return scenariosList();
};
