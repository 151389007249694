import { useEffect, useState } from "react";
import {
  BoldText,
  CheckContainer,
  CheckUrl,
  ErrorMessage,
  SuccessMessage,
  InsideTable,
  InsideTableBody,
  InsideTableCell,
  InsideTableHeaderCell,
  Label,
  LabelWrapper,
  Link,
  Section,
  Status,
  Table,
  TableCell,
  TableContainer,
  ViolationsBugWrapper,
  ViolationsContainer,
  NoChecksMessage,
} from "./axeSection.styles";
import SyntaxHighlighter from "react-syntax-highlighter";
import { useTheme } from "../../../context/theme/theme.provider";
import {
  a11yDark,
  a11yLight,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import { unEscapeChars } from "../../../utils/unescapeChars/unescapeChars";
import { useTranslation } from "react-i18next";
import { useApp } from "../../../context/app/app.provider";
import { useLocation } from "react-router-dom";
import { TEST_CATEGORIES } from "../../../static/constants/constants";

const STATUS_NO_CHECKS = 1;
const STATUS_NO_VIOLATIONS = 2;
const STATUS_VIOLATIONS = 3;
const STATUS_ERROR_OCCURRED = 4;

export const AxeSection = ({
  check,
  stepRef,
  JiraBugButton,
  scenario,
  feature,
}) => {
  const { isDark } = useTheme();
  const { t } = useTranslation();
  const [tableIsOpen, setTableIsOpen] = useState(false);
  const { isMobile } = useApp();
  const { hash } = useLocation();
  const url = `#${stepRef}`;

  useEffect(() => {
    if (url === hash) setTableIsOpen(true);
  }, []);

  const CheckWithViolations = () =>
    check.violations && check.violations.length ? (
      <>
        <CheckUrl>{check.url}</CheckUrl>
        <ViolationsBugWrapper>
          <ViolationsContainer>
            <Status>
              <h1>{check.critical}</h1>
              <p>{t("runCard.testevolveaccessibility.violations.critical")}</p>
            </Status>
            <Status>
              <h1>{check.serious}</h1>
              <p>{t("runCard.testevolveaccessibility.violations.serious")}</p>
            </Status>
            <Status>
              <h1>{check.moderate}</h1>
              <p>{t("runCard.testevolveaccessibility.violations.moderate")}</p>
            </Status>
            <Status>
              <h1>{check.minor}</h1>
              <p>{t("runCard.testevolveaccessibility.violations.minor")}</p>
            </Status>
          </ViolationsContainer>
        </ViolationsBugWrapper>
        <JiraBugButton
          feature={feature}
          data={check}
          url={url}
          scenario={scenario}
          category={TEST_CATEGORIES.TEST_EVOLVE_AUDIT_AXE}
        />
      </>
    ) : (
      <ErrorMessage>{t("runReport.axeError")}</ErrorMessage>
    );

  const AxeStatusSwitch = () => {
    switch (check.status) {
      case STATUS_NO_CHECKS:
        return <NoChecksMessage>{t("runReport.axeNoChecks")}</NoChecksMessage>;
      case STATUS_NO_VIOLATIONS:
        return (
          <SuccessMessage>{t("runReport.axeNoViolations")}</SuccessMessage>
        );
      case STATUS_VIOLATIONS:
        return <CheckWithViolations />;
      case STATUS_ERROR_OCCURRED:
        return <ErrorMessage>{t("runReport.axeError")}</ErrorMessage>;
      default:
        return <ErrorMessage>{t("runReport.axeError")}</ErrorMessage>;
    }
  };

  return (
    <>
      <Section
        key={check.label}
        onClick={() => setTableIsOpen(open => !open)}
        dark={isDark}
        id={stepRef}
        data-test="axe-check"
      >
        <CheckContainer isMobile={isMobile} id={url}>
          <LabelWrapper>
            <Label>{check.label}</Label>
          </LabelWrapper>
          <AxeStatusSwitch />
        </CheckContainer>
      </Section>
      {tableIsOpen && check.violations && check.violations.length
        ? check.violations.map(v => (
            <TableContainer key={v.id} data-test="axe-entry">
              <Table isMobile={isMobile}>
                <TableCell>
                  <p>{v.impact}</p>
                </TableCell>
                <TableCell>
                  <BoldText>{v.id}</BoldText>
                  <p>{v.help}</p>
                </TableCell>
                <TableCell>
                  <Link external={true} to={v.helpUrl}>
                    {t("runCard.testevolveaccessibility.axeGuide")}
                  </Link>
                </TableCell>
                <TableCell>
                  <h4>{t("runView.testevolveaccessibility.description")}</h4>
                  <p>{v.description}</p>
                  <InsideTable>
                    {v.nodes?.map((node, id) => (
                      <InsideTableBody key={node.source} isMobile={isMobile}>
                        <InsideTableCell isMobile={isMobile}>
                          {!id && (
                            <InsideTableHeaderCell>
                              {t("runCard.testevolveaccessibility.target")}
                            </InsideTableHeaderCell>
                          )}
                          {node.target?.map(target => (
                            <SyntaxHighlighter
                              wrapLongLines={true}
                              wrapLines={true}
                              language={"yml"}
                              style={isDark ? a11yDark : a11yLight}
                              className="target"
                              key={target}
                            >
                              {target}
                            </SyntaxHighlighter>
                          ))}
                        </InsideTableCell>
                        <InsideTableCell isMobile={isMobile}>
                          {!id && (
                            <InsideTableHeaderCell>
                              {t("runCard.testevolveaccessibility.source")}
                            </InsideTableHeaderCell>
                          )}
                          <SyntaxHighlighter
                            wrapLongLines={true}
                            wrapLines={true}
                            language={"yml"}
                            style={isDark ? a11yDark : a11yLight}
                            className="source"
                          >
                            {unEscapeChars(node.source)}
                          </SyntaxHighlighter>
                        </InsideTableCell>
                        <InsideTableCell isMobile={isMobile}>
                          {!id && (
                            <InsideTableHeaderCell>
                              {t(
                                "runCard.testevolveaccessibility.instructions"
                              )}
                            </InsideTableHeaderCell>
                          )}
                          <p>{unEscapeChars(node.instructions)}</p>
                        </InsideTableCell>
                      </InsideTableBody>
                    ))}
                  </InsideTable>
                </TableCell>
              </Table>
            </TableContainer>
          ))
        : null}
    </>
  );
};

export const AxeSections = ({
  axe_checks,
  JiraBugButton,
  stepRef,
  scenario,
  feature,
}) =>
  axe_checks?.map((check, index) => (
    <AxeSection
      key={check.label}
      check={check}
      stepRef={`${stepRef}-${index}`}
      JiraBugButton={JiraBugButton}
      scenario={scenario}
      feature={feature}
    />
  ));
