import React from "react";
import { routes } from "../../router/routes";
import { useTranslation } from "react-i18next";
import { useUser } from "../../context/user/user.provider";
import { DropdownMenu } from "./dropdownMenu/dropdownMenu";
import { useDropdown } from "./dropdownMenu/hooks/useDropdown";
import {
  Logo,
  Username,
  ProfileIcon,
  StyledHeader,
  MenuContainer,
  LogoContainer,
  MenuIcon,
  CrossIcon,
} from "./header.styles";
import { useApp } from "../../context/app/app.provider";
import { useTheme } from "styled-components";
import { useNavOpen } from "../../context/navigation/nav.provider";
import { useHistoryWithParams } from "../../context/hooks/historyWithParams/useHistoryWithParams";

export const Header = ({ outsideProject }) => {
  const history = useHistoryWithParams();
  const { t } = useTranslation();
  const { username } = useUser();
  const { isOpen, toggleOpen } = useDropdown();
  const { isMobile } = useApp();
  const { colours } = useTheme();
  const { togglePane, navIsOpen } = useNavOpen();
  const { isNotDesktop } = useApp();

  return (
    <StyledHeader isNotDesktop={isNotDesktop} role="banner">
      {!outsideProject && isNotDesktop ? (
        navIsOpen ? (
          <CrossIcon onClick={() => togglePane()} />
        ) : (
          <MenuIcon onClick={() => togglePane()} fill={colours.mainText} />
        )
      ) : null}
      <LogoContainer>
        <Logo
          $ismobile={isMobile}
          onClick={() =>
            outsideProject
              ? history.push(routes.PROJECTS.path)
              : history.push(routes.HOME.path)
          }
        />
        {!isMobile ? <p>{t("header.logoSupportText")}</p> : null}
      </LogoContainer>
      <MenuContainer isOpen={isOpen} onClick={() => toggleOpen()}>
        {!isMobile ? <Username>{username}</Username> : null}
        <ProfileIcon />
      </MenuContainer>
      {isOpen ? <DropdownMenu toggleOpen={() => toggleOpen()} /> : null}
    </StyledHeader>
  );
};
