import React from "react";
import { useTranslation } from "react-i18next";
import { NodeCard } from "../../common/NodeCard";
import { Violations } from "./violations/violations";
import { useNodeView } from "../../hooks/useNodeView";
import { sumObject } from "../../../../utils/objectMethods/objectMethods";
import {
  Container,
  SummariesContainer,
  Summary,
} from "./nodeCardTestEvolveAccessibility.styles";
import { useLatestRun } from "../../../runView/testEvolveAccessibility/latestRunCard/hooks/useLatestRun";

export const NodeCardTestEvolveAccessibility = ({
  run,
  getLabels,
  onRelease,
  frozen,
  isShared,
}) => {
  const { t } = useTranslation();
  const { nodeId } = useNodeView();
  const { status, runStatus, runProps } = useLatestRun(run);

  let newObj = {};

  const keys = Object.keys(run.axe_summary).filter(
    val => !["standards", "checksPerformed"].includes(val)
  );
  keys.forEach(key => (newObj[key] = run.axe_summary[key]));

  return (
    <NodeCard
      data-test="te-accessibility-card"
      type={run.category.replace(".", "")}
      run={run}
      status={status}
      runStatus={runStatus}
      startTime={run.start_time}
      nodeId={nodeId}
      category={run.category}
      label={run.label}
      getLabels={getLabels}
      runProps={runProps}
      frozen={frozen}
      onRelease={onRelease}
      isShared={isShared}
    >
      <Container>
        <SummariesContainer>
          <Summary>
            <h1 data-test="te-accessibility-checksperformed-title">
              {run.axe_summary.checksPerformed}
            </h1>
            <h2 data-test="te-accessibility-checksperformed-val">
              {t("runCard.testevolveaccessibility.totalChecksCompleted")}
            </h2>
          </Summary>
          <Summary>
            <h1 data-test="te-accessibility-totalviolations-title">
              {sumObject(Object.values(newObj))}
            </h1>
            <h2 data-test="te-accessibility-totalviolations-val">
              {t("runCard.testevolveaccessibility.totalViolations")}
            </h2>
          </Summary>
        </SummariesContainer>
        <Violations data={run.axe_summary} />
      </Container>
    </NodeCard>
  );
};
