import React from "react";
import {
  Chevron,
  StickerContainer,
  ViolationsContainer,
} from "./violations.styles.js";
import { useTranslation } from "react-i18next";
import { assets } from "../../../../../static/assets/assets";

export const Violations = ({ data }) => {
  const { t } = useTranslation();

  return (
    <ViolationsContainer>
      <StickerContainer data-test="te-accessibility-critical">
        <Chevron
          {...assets.icons.navChevron}
          color={({ theme }) => theme.colours.severity.highest}
        />
        <h1>{data.critical}</h1>
        <p>{t("runCard.testevolveaccessibility.violations.critical")}</p>
      </StickerContainer>
      <StickerContainer data-test="te-accessibility-high">
        <Chevron
          {...assets.icons.navChevron}
          color={({ theme }) => theme.colours.severity.high}
          rotate="45"
        />
        <h1>{data.serious}</h1>
        <p>{t("runCard.testevolveaccessibility.violations.serious")}</p>
      </StickerContainer>
      <StickerContainer data-test="te-accessibility-moderate">
        <Chevron
          {...assets.icons.navChevron}
          color={({ theme }) => theme.colours.severity.medium}
          rotate="90"
        />
        <h1>{data.moderate}</h1>
        <p>{t("runCard.testevolveaccessibility.violations.moderate")}</p>
      </StickerContainer>
      <StickerContainer data-test="te-accessibility-minor">
        <Chevron
          {...assets.icons.navChevron}
          color={({ theme }) => theme.colours.severity.low}
          rotate="180"
        />
        <h1>{data.minor}</h1>
        <p>{t("runCard.testevolveaccessibility.violations.minor")}</p>
      </StickerContainer>
    </ViolationsContainer>
  );
};
