import { TEST_CATEGORIES } from "../../../static/constants/constants";
import { NodeCardNPMAudit } from "./nodeCardNPMAudit/nodeCardNPMAudit";
import { NodeCardTestEvolve } from "./nodeCardTestEvolve/nodeCardTestEvolve";
import { NodeCardTestEvolveVisual } from "./nodeCardTestEvolveVisual/nodeCardTestEvolveVisual";
import { NodeCardTestEvolveLighthouse } from "./nodeCardTestEvolveLighthouse/nodeCardTestEvolveLighthouse";
import { NodeCardTestEvolveAccessibility } from "./nodeCardTestEvolveAccessibility/nodeCardTestEvolveAccessibility";
import { NodeCardTestEvolveManual } from "./nodeCardTestEvolveManual/nodeCardTestEvolveManual";

export const NodeCard = ({ category, ...props }) => {
  switch (category) {
    case TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL:
      return <NodeCardTestEvolve {...props} />;
    case TEST_CATEGORIES.TEST_EVOLVE_VISUAL:
      return <NodeCardTestEvolveVisual {...props} />;
    case TEST_CATEGORIES.TEST_EVOLVE_AUDIT_AXE:
      return <NodeCardTestEvolveAccessibility {...props} />;
    case TEST_CATEGORIES.TEST_EVOLVE_AUDIT_LIGHTHOUSE:
      return <NodeCardTestEvolveLighthouse {...props} />;
    case TEST_CATEGORIES.TEST_EVOLVE_EXPLORATORY:
      return <NodeCardTestEvolveManual {...props} />;
    case TEST_CATEGORIES.NPM_AUDIT:
      return <NodeCardNPMAudit {...props} />;
    default:
      return <NodeCardTestEvolve {...props} />;
  }
};
